import axios from "axios";
import { ADMIN_ENDPOINT } from "../config/api";
import { encryptParams } from "./string";
import { store } from "../modules/redux/sagas";
import { storageKeys } from "../constants/storage";

const instance = axios.create({
  baseURL: ADMIN_ENDPOINT,
  transformRequest: [data => JSON.stringify(encryptParams(data))]
});

instance.interceptors.request.use(
  async config => {
    config.headers = {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json"
    };

    const token =  store.getState().authReducer.token;
    if (token) {
      config.headers.authorization = token;
    }
    return config;
  },
  err => Promise.reject(err)
);

instance.interceptors.response.use(
  response => response.data,
  error => {
    const response = error.response;
    // response.config.url !== "login" 
    if ((response.data.message == "Invalid or Expired Token; Please login again" && response.status === 401) ||
    (response.data.message == "Invalid tokens" && response.status === 401)
    ) {
      localStorage.removeItem(storageKeys.TOKEN)
      localStorage.removeItem(storageKeys.USER)
      window.location = "/login"
    }
    return Promise.reject(error.response.data);
  }
);

export default instance;
