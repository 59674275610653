// Auth
export const LOGIN_URL = '/intake-form/login';

export const LOCATION_URL = '/authSteps';

//Submit Form
export const INTAKE_FORM = '/intake-form/campaign';
export const UPLOAD_FILE = '/intake-form/campaign/uploadFile';
export const COMPAIGN_BY_ID = '/intake-form/campaign/get-by-id';
export const COMPAIGN_LIST_URL = '/intake-form/campaign/list-by-stakeholder'
export const COMPAIGN_DELETE_URL = '/intake-form/campaign/delete-by-stakeholder'
export const INTAKE_FORM_EDIT = '/intake-form/campaign/update-by-stakeholder';

//Change Password & Reset Password
export const CHANGE_PASSWORD = '/intake-form/user/stakeholder/change-password';
export const CHECK_TOKEN_SENDEMIAL = '/intake-form/user/stakeholder/token-used';
export const RESET_PASSWORD = '/intake-form/user/stakeholder/reset-password'
