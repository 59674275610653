const StatesList = [
  {
    key: "AL",
    name: "Alabama"
  },
  {
    key: "AK",
    name: "Alaska"
  },
  {
    key: "AZ",
    name: "Arizona"
  },
  {
    key: "AR",
    name: "Arkansas"
  },
  {
    key: "CA",
    name: "California"
  },
  {
    key: "CO",
    name: "Colorado"
  },{
    key: "CT",
    name: "Connecticut"
  },{
    key: "DE",
    name: "Delaware"
  },
  {
    key: "FL",
    name: "Florida"
  },{
    key: "GA",
    name: "Georgia"
  }, {
    key: "HI",
    name: "Hawaii"
  }, {
    key: "ID",
    name: "Idaho"
  },{
    key: "IL",
    name: "Illinois"
  },{
    key: "IN",
    name: "Indiana",
  },{
    key: "IA",
    name: "Iowa"
  }, {
    key: "KS",
    name: "Kansas"
  },{
    key: "KY",
    name: "Kentucky"
  },{
    key: "LA",
    name: "Louisiana"
  },{
    key: "ME",
    name: "Maine"
  },{
    key: "MD",
    name: "Maryland"
  },{
    key: "MA",
    name: "Massachusetts"
  },{
    key: "MI",
    name: "Michigan"
  },{
    key: "MN",
    name: "Minnesota"
  },{
    key: "MS",
    name: "Mississippi"
  },{
    key: "MO",
    name: "Missouri"
  },{
    key: "MT",
    name: "Montana"
  },{
    key: "NE",
    name: "Nebraska"
  },{
    key: "NV",
    name: "Nevada"
  },{
    key: "NH",
    name: "New Hampshire"
  },{
    key: "NJ",
    name: "New Jersey"
  },{
    key: "NM",
    name: "New Mexico"
  },{
    key: "NY",
    name: "New York"
  },{
    key: "NC",
    name: "North Carolina"
  },{
    key: "ND",
    name: "North Dakota"
  },{
    key: "OH",
    name: "Ohio"
  },{
    key: "OK",
    name: "Oklahoma"
  },{
    key: "OR",
    name: "Oregon"
  },{
    key: "PA",
    name: "Pennsylvania"
  },{
    key: "RI",
    name: "Rhode Island"
  },{
    key: "SC",
    name: "South Carolina"
  },{
    key: "SD",
    name: "South Dakota"
  },{
    key: "TN",
    name: "Tennessee"
  },{
    key: "TX",
    name: "Texas"
  },{
    key: "UT",
    name: "Utah"
  },{
    key: "VT",
    name: "Vermont"
  },{
    key: "VA",
    name: "Virginia"
  },{
    key: "WA",
    name: "Washington"
  },{
    key: "WV",
    name: "West Virginia"
  },{
    key: "WI",
    name: "Wisconsin"
  },{
    key: "WY",
    name: "Wyoming"
  },{
    key: "PR",
    name: "Puerto Rico"
  }
];

export const PRCity = [
  {
    address: {
      country: "1",
      state: "PR",
      city: "Ponce",
      zipCode: "36116",
      addressLine1: "Ponce 951"
    },
    locationID: "951",
    name: "Ponce 951"
  },
  {
    address: {
      country: "1",
      state: "PR",
      city: "Loíza",
      zipCode: "36116",
      addressLine1: "Loíza 952"
    },
    locationID: "952",
    name: "Loíza 952"
  },
  {
    address: {
      country: "1",
      state: "PR",
      city: "Toa Baja",
      zipCode: "36116",
      addressLine1: "Toa Baja 953"
    },
    locationID: "953",
    name: "Toa Baja 953"
  },
]

export const parseCitiesFromPlasmaCenters = (plasmaCenters, state) => {
let citiesPlasmaCenterFilter = []
  for(let i = 0; i < state.length; i++){
    let stateData = state[i]
    if(stateData !== "") {
        const stateCenter = plasmaCenters.filter((pl) => pl.address && (pl.address.state === stateData))
        citiesPlasmaCenterFilter.push(stateCenter)
        const flat = citiesPlasmaCenterFilter.flat(Infinity)
        citiesPlasmaCenterFilter = flat
    } else {
        citiesPlasmaCenterFilter = plasmaCenters
    }
  }

  if(!citiesPlasmaCenterFilter) {
    return []
  }
  
  const cities = []
  for(let i = 0; i < citiesPlasmaCenterFilter.length; i++) {
      let city = citiesPlasmaCenterFilter[i].address.city
      let exist = cities.find((v) => v === city)
      if(!exist) {
          cities.push(city)
      }
  }
  cities.sort()
  return cities
}

export const parseStatesFromPlasmaCenters = (plasmaCenters) => {
  if(!plasmaCenters) {
    return []
  }
  const states = []
  for(let i = 0; i < plasmaCenters.length; i++) {
    if(plasmaCenters[i].address) {
      let state = plasmaCenters[i].address.state
      let exist = states.find((v) => v.key === state)
      if(!exist) {
          const origiState = StatesList.find(sl => sl.key === state);
          states.push(origiState)
      }
    }
  }
  states.sort((a,b) => (a.key.toLowerCase() < b.key.toLowerCase()) ? -1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? 1 : 0));

  return states
}

export const parsePlasmaCentersByStateAndCity = (plasmaCenters, state, city) => {
if(!plasmaCenters) {
  return []
}

let plasma_centers = plasmaCenters
let centers = []
let centersDetail = []

for(let i = 0; i < state.length; i++){
  let stateData = state[i]
  if(stateData !== "") {
      const findCenter = plasma_centers.filter((pl) => pl.address.state === stateData)
      centers.push(findCenter)
      const flat = centers.flat(Infinity)
      centers = flat
  }
}
for(let i = 0; i < city.length; i++){
  let cityData = city[i]
  if(cityData !== "") {
    const centerFound = centers.filter((pl) => pl.address.city === cityData)
    centersDetail.push(centerFound)
    const flat = centersDetail.flat(Infinity)
    centersDetail = flat
  }
}

return centersDetail
}