import React from 'react'
import CSLPlasma from '../../assets/images/csl_logo_4.png'
import './desktopHeader.css'
import { useState } from 'react'
import { MdOutlineAccountCircle } from 'react-icons/md'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

const DesktopHeader = (props) => {
    const { user, token } = props;
    const [activeMenu, setActiveMenu] = useState()
    const [showDrop, setShowDrop] = useState(false)

    const menus = [
        {
            target: "_blank",
            link: "/",
            title: "New Form",
        },
    ]
    const logoutHandler = () => {
        localStorage.clear();
        window.location.href = "/login";
    };
    const changePasswordHandler = () => {
        window.location.href = "/change/password";
    };

    return (
        <div className='desktop_header d-lg-flex d-none sidebars'>
            <div className='container d-flex flex-row align-items-center p-0'>
                <Link to="/" rel="noopener noreferrer" className='h-100 d-flex align-items-center mr-1'>
                    <img alt='' src={CSLPlasma} style={{ width: '110px', marginRight: '50px' }} />
                </Link>
                {token ?
                    <div className='row w-100'>
                        <div className='col-xxl-7 col-12 p-0 d-flex align-items-center'>
                        </div>
                        <div className='col-xxl-5 col-12 p-0 d-flex align-items-center justify-content-end' style={{ height: '80px' }}>
                            <div className='d-flex flex-row align-items-center'>
                                {
                                    menus.map((m, index) => (
                                        <div key={index} className="dropdown" style={{ cursor: "pointer" }}>
                                            <a
                                                onClick={() => {
                                                    if (m.link === "#") {
                                                    } else if (m.link === "#referral") {
                                                    } else {
                                                        setActiveMenu(m.link)
                                                    }
                                                }}
                                                onMouseOver={() => {
                                                    setShowDrop(!showDrop)
                                                    setActiveMenu(m.link)
                                                }}
                                                href={m.link}
                                                className='navigation_text showDrops' style={{ textDecoration: 'none', padding: '15px', color: 'black' }}>
                                                {m.title}
                                            </a>
                                            {
                                                m.children && m.children.length > 0 && (
                                                    <ul className={`dropdown-menu header-menu ${showDrop && m.link === activeMenu ? "show" : ""}`} show aria-labelledby="dropdownMenuButton1">
                                                        {
                                                            m.children && m.children.map((ch, Inch, index) => (
                                                                <li key={index}><a className="dropdown-item" href={ch.link}>{ch.title}</a></li>
                                                            ))
                                                        }
                                                    </ul>
                                                )
                                            }
                                        </div>
                                    ))
                                }
                                <div className='d-flex flex-row align-items-center dropdown customize' >
                                    <div className="btn dropdown--toggle" type="button" data-bs-toggle="dropdown">
                                        <MdOutlineAccountCircle size={40} />
                                        <button className="btn dropdown--toggle" type="button" data-bs-toggle="dropdown" style={{ fontSize: "14px", marginLeft: '-10px' }}>
                                            <p className='m-0 text-capitalize' style={{ cursor: 'pointer', fontSize: '14px', fontWeight: 600 }}>{user.firstName} {user.lastName}</p>
                                            <p className='text-capitalize' style={{ cursor: 'pointer', fontSize: '14px', textAlign: 'left' }}>{user.userName}</p>
                                        </button>
                                        <RiArrowDropDownLine size={25} />
                                        <div className="dropdown-menu header-menu customMenu" style={{ marginTop: '-8px' }} aria-labelledby="dropdownMenuButton" >
                                            <Link to={'#'} style={{ cursor: 'pointer', fontSize: '14px', fontWeight: 600 }} onClick={() => { logoutHandler(); }} className="dropdown-item">Log Out</Link>
                                            <Link to='/change-password' style={{ cursor: 'pointer', fontSize: '14px', fontWeight: 600 }} className="dropdown-item">Change Password</Link>
                                            <Link to='/submitFormList' style={{ cursor: 'pointer', fontSize: '14px', fontWeight: 600 }} className="dropdown-item">Submitted Forms</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}

            </div>
        </div>
    )
}

export default DesktopHeader