import React from 'react'
import { Button } from 'react-bootstrap'
import { colors } from '../../constants/theme'

const RoundButton = props => {
    return <Button
        {...props}
        size="sm" 
        variant="primary"
        style={{
            marginTop: 10,
            background: colors.primaryColor,
            borderColor: colors.primaryColor,
            borderRadius: 30,
            width: props.width ? props.width : 250,
            fontFamily: 'Montserrat',
            fontWeight: 800,
            fontSize: 16,
            marginBottom: 5,
            height: 60
        }}>{props.title}</Button>
}

export const RoundButtonCancel = props => {
    return <Button
        {...props}
        size="sm" 
        variant="primary"
        style={{
            marginTop: 10,
            color: colors.borderBtnColor,
            background: colors.whiteColor,
            borderColor: colors.borderBtnColor,
            borderRadius: 30,
            width: props.width ? props.width : 250,
            fontFamily: 'Montserrat',
            fontWeight: 800,
            fontSize: 16,
            marginBottom: 5,
            height: 60
        }}>{props.title}</Button>
}

export default RoundButton;