import React from 'react'

const BlockLoading = () => {
    return (
        <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, right: 0, cursor: 'default' }}>
            <div style={{ backgroundColor: 'black', width: '100%', height: '100%', position: 'absolute', zIndex: '1', opacity: '0.1', borderRadius: '3px' }} />
            <div style={{ width: '100%', height: '100%', zIndex: '2', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute' }}>
                <div style={{ height: `100%`, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <center>
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </center>
                </div>
            </div>
        </div>
    )
}

export default BlockLoading