import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../assets/images/icons/calendar-icon.svg'
import { CalendarCSL } from '../container/calendar/CalendarCSL';
import DropupIcon from '../assets/images/icons/dropup-icon.svg'
import DropdownIcon from '../assets/images/icons/dropdown-icon.svg'
import moment from 'moment';
import { formatDate, formatDateAPI2, formatDateString } from '../functions/format/dateFormat';

const SelectDonationHistory = (props) => {
  const {
    keyAmount,
    setKeyAmount,
    setKeyDate,
    keyDate,
    setOtherComment,
    otherComment,
    setDonationHistory,
    donationHistory,
    donationAmount,
    setDonationAmount,
    dateAmount,
    validateAudience
  } = props
  const [activeOtherText, setActiveOtherText] = useState()

  const [amountLabel, setAmountLabel] = useState()
  const [amountActive, setAmountActive] = useState()

  const [dateLabel, setDateLabel] = useState()
  const [dateActive, setDateActive] = useState()

  const [formDateActive, setFormDateActive] = useState()
  const [toFormDateActive, setToFormDateActive] = useState()
  const [fromFormDateActive, setFromFormDateActive] = useState()

  const [formDateValue, setFormDateValue] = useState()
  const [toFormDateValue, setToFormDateValue] = useState()
  const [fromFormDateValue, setFromFormDateValue] = useState();

  const [donationHistoryCheck, setdonationHistoryCheck] = useState({
    noDonation: false,
    donationAmount: false,
    donationDate: false,
    otherHistory: false,
  })

  const amountOptions = [
    { value: "less-then", label: "Less Then" },
    { value: "greater-then", label: "Greater Than" },
    { value: "between", label: "Between" }
  ];

  const dateOptions = [
    { value: "before", label: "Before" },
    { value: "after", label: "After" },
    { value: "between", label: "Between" }
  ];

  const [amountValue, setAmountValue] = useState({
    singleAmount: '',
    beforeAmount: '',
    afterAmount: ''
  })
  useEffect(() => {
    setDonationAmount(donationAmount)
  }, [donationAmount])

  useEffect(() => {
    if(donationHistory.otherHistory === 'yes') {
      setActiveOtherText(true)
    }
  }, [donationHistory])

  useEffect(() => {
    if(dateAmount){
      let mountD = dateOptions.find(it => it.value === keyDate)
      if(mountD){
        setDateLabel(mountD.label)
        setKeyDate(mountD.value)
        setDateActive(false)
        if( mountD.value === 'before' || mountD.value === 'after') {
          if(dateAmount[0]){
            let fromD =  formatDateString(dateAmount[0]);
            dateAmount[1] = null
            setFormDateValue(new Date(fromD))
            setToFormDateValue('')
          }
        } else {
          if(dateAmount[0]){
            let fromD =  formatDateString(dateAmount[0]);
            setFromFormDateValue(new Date(fromD))
          }
          if(dateAmount[1]) {
            let endD =  formatDateString(dateAmount[1]);
            setToFormDateValue(new Date(endD))
          }
        }
      }
    }

    document.addEventListener("click", handleClickOutSide, true)
    return () => {
        document.removeEventListener('click', handleClickOutSide);
      };
  }, [dateAmount])

  const refOne = useRef(null)

    const handleClickOutSide = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
          setFormDateActive(false)
          setToFormDateActive(false)
          setFromFormDateActive(false)
        }
    }

  useEffect(() => {
    if(keyAmount ){
      let mount = amountOptions.find(it => it.value === keyAmount)
      if(mount){
        setAmountLabel(mount.label)
        setKeyAmount(mount.value)
        setAmountActive(false)
        if( mount.value === 'less-then' || mount.value === 'greater-then') {
          setAmountValue({
            ...amountValue,
            singleAmount: donationAmount[0]
          })
        } else {
          setAmountValue({
            ...amountValue,
            beforeAmount: donationAmount[0],
            afterAmount: donationAmount[1]
          })
        }
      }
    }
  }, [keyAmount])

  const handleSelectAmount = (item) => {
    setAmountLabel(item.label)
    setKeyAmount(item.value)
    setAmountActive(false)
    validateAudience()
  }

  const handleSelectDate = (item) => {
    setDateLabel(item.label)
    setKeyDate(item.value)
    setDateActive(false)
    setToFormDateValue(null)
    setToFormDateValue(null)
    setFromFormDateValue(null)
    dateAmount[0] = null
    dateAmount[1] = null
    validateAudience()
  }

  const handleChangeDate = (date) => {
    setFormDateValue(date)
    dateAmount[0] = formatDateAPI2(date);
    dateAmount[1] = null
    validateAudience()
  }

  const handleChangeToDate = (date) => {
    setToFormDateValue(date)
    dateAmount[1] = formatDateAPI2(date);
    validateAudience()
  }

  const handleChangeFromDate = (date) => {
    setFromFormDateValue(date)
    dateAmount[0] = null
    if (date) {
      dateAmount[0] = formatDateAPI2(date);
      validateAudience()
    }
  }
    
  useEffect(() => {
    if(donationHistory) {
      setdonationHistoryCheck({
        noDonation: donationHistory.noDonation === 'yes',
        donationAmount: donationHistory.donationAmount === 'yes',
        donationDate: donationHistory.donationDate === 'yes',
        otherHistory: donationHistory.otherHistory === 'yes',
      })
    }
  }, [donationHistory])

  const handleCheckDonationHistory = (event) => {
    const { value, checked } = event.target;
    // donationHistory.noDonation = 'no'
    // donationHistory.donationAmount = 'no'
    // donationHistory.donationDate = 'no'
    // donationHistory.otherHistory = 'no'
    setdonationHistoryCheck({...donationHistoryCheck,
      [value]: !donationHistoryCheck[value]
    })
    if (!donationHistoryCheck[value]) {
      if(value === 'donationDate') {
        setKeyDate('')
        setDateLabel('')
        setFormDateValue('')
        setFromFormDateValue('')
        setToFormDateValue('')
        dateAmount[0] = null
        dateAmount[1] = null
      }
      if(value === 'donationAmount') {
        setAmountLabel('')
        setKeyAmount('')
        setAmountValue({
          singleAmount: '',
          beforeAmount: '',
          afterAmount: ''
        })
        donationAmount[0] = null;
        donationAmount[1] = null
      }
      setDonationHistory({
        ...donationHistory,
        [value]: 'yes'
      })
    } else {
      if(value === 'donationDate') {
        setKeyDate('')
        setDateLabel('')
        setFormDateValue('')
        setFromFormDateValue('')
        setToFormDateValue('')
        dateAmount[0] = null
        dateAmount[1] = null
      }
      if(value === 'donationAmount') {
        setAmountLabel('')
        setKeyAmount('')
        setAmountValue({
          singleAmount: '',
          beforeAmount: '',
          afterAmount: ''
        })
        donationAmount[0] = null;
        donationAmount[1] = null
      }
      setDonationHistory({
        ...donationHistory,
        [value]: 'no'
      })
    }
    if (value === "otherHistory") {
      setActiveOtherText(checked)
    }
  }

  const onChangeDonationAmount = (event) => {
    const { value, id } = event.target;
    if (id === 'singleAmount') {
      donationAmount[0] = value;
      donationAmount[1] = null
      setAmountValue({
        ...amountValue,
        singleAmount: value
      })
    } else if (id === 'beforeAmount') {
      donationAmount[0] = value
      setAmountValue({
        ...amountValue,
        beforeAmount: value
      })
    }
    else if (id === 'afterAmount') {
      donationAmount[1] = value
      setAmountValue({
        ...amountValue,
        afterAmount: value
      })
    }
    validateAudience()
  }

  return (
    <div>
      <p className="size14700" style={{ marginBottom: '12px' }}>Donation History (please select all that apply)</p>
      <div>
        <div class="form-check">
          <input
            className='input-check'
            type="checkbox"
            id="No-Donation"
            name="newDonors"
            value="noDonation"
            checked={donationHistoryCheck.noDonation}
            onClick={handleCheckDonationHistory}
          />
          <label className='size16700' for="No-Donation">
            No Donations
          </label>
        </div>
        <div className='row' style={{ marginTop: '12px' }}>
          <div className='col-md-4 col'>
            <div className='d-flex align-items-center' style={{ height: '100%' }}>
              <div class="form-check">
                <input
                  className='input-check'
                  type="checkbox"
                  id="Donation-amount"
                  name="newDonors"
                  value="donationAmount"
                  checked={donationHistoryCheck.donationAmount}
                  onClick={handleCheckDonationHistory}
                />
                <label className='size16700' for="Donation-amount">
                  Donation Amount
                </label>
              </div>
            </div>
          </div>
          <div className='col-md-8 col'>
            <div className='d-flex flex-row align-items-center'>
              <div style={{ position: 'relative' }}>
                <button
                  style={{
                    height: '50px',
                    width: '160px',
                    backgroundColor: 'white',
                    border: '1px solid #ced4da',
                    borderRadius: '5px',
                    padding: '9px',
                    position: 'relative',
                    marginRight: '16px'
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setAmountActive(!amountActive)
                  }}
                  className='d-flex flex-row align-items-center'
                >
                  <p className="size16600 mb-0">{amountLabel ? amountLabel : 'Select One'}</p>
                  <img style={{ position: 'absolute', right: '9px', top: '18px' }} alt="" src={amountActive ? DropupIcon : DropdownIcon} width='18px' />
                </button>
                <div className={`dropdown-menu ${amountActive ? 'show' : ''}`} style={{ zIndex: 10 }}>
                  {amountOptions.map((item, index) => (
                    <>
                      <a href class="dropdown-item size16400" onClick={() => handleSelectAmount(item)} style={{ padding: '9px', cursor: 'pointer' }}>{item.label}</a>
                      {index + 1 !== amountOptions.length ? <div className="dropdown-divider"></div> : ''}
                    </>
                  ))}
                </div>
              </div>
              {
                keyAmount === "between" ? (
                  <div className='d-flex flex-row align-items-center'>
                    <input style={{ height: '50px' }} type="number" id='beforeAmount' class="form-control custom-input 1 " value={amountValue.beforeAmount} onChange={onChangeDonationAmount} aria-describedby="emailHelp" />
                    <div style={{ width: '10px', height: '1px', backgroundColor: 'black', marginLeft: '16px', marginRight: '16px' }}></div>
                    <input style={{ height: '50px' }} type="number" id='afterAmount' class="form-control custom-input 2" value={amountValue.afterAmount} onChange={onChangeDonationAmount} aria-describedby="emailHelp" />
                  </div>
                ) : (
                  <input style={{ height: '50px' }} type="number" value={amountValue.singleAmount} onChange={onChangeDonationAmount} id='singleAmount' class="form-control custom-input 3" aria-describedby="emailHelp" />
                )
              }
            </div>
          </div>
        </div>
        <div className='row' style={{ marginTop: '12px' }}>
          <div className='col-md-4 col'>
            <div className='d-flex align-items-center' style={{ height: '100%' }}>
              <div class="form-check">
                <input
                  className='input-check'
                  type="checkbox"
                  id="Donation-date"
                  name="newDonors"
                  value="donationDate"
                  checked={donationHistoryCheck.donationDate}
                  onClick={handleCheckDonationHistory}
                />
                <label className='size16700' for="Donation-date">
                  Donation Date
                </label>
              </div>
            </div>
          </div>
          <div className='col-md-8 col'>
            <div className='d-flex flex-row align-items-center relative' style={{ width: '100%' }}>
              <div style={{ position: 'relative' }}>
                <button
                  style={{
                    height: '50px',
                    width: '160px',
                    backgroundColor: 'white',
                    border: '1px solid #ced4da',
                    borderRadius: '5px',
                    padding: '9px',
                    position: 'relative',
                    marginRight: '16px'
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setDateActive(!dateActive)
                  }}
                  className='d-flex flex-row align-items-center'
                >
                  <p className="size16600 mb-0">{dateLabel ? dateLabel : 'Select One'}</p>
                  <img style={{ position: 'absolute', right: '9px', top: '18px' }} alt="" src={dateActive ? DropupIcon : DropdownIcon} width='18px' />
                </button>
                <div className={`dropdown-menu ${dateActive ? 'show' : ''}`} style={{ zIndex: 10 }}>
                  {dateOptions.map((item, index) => (
                    <>
                      <a href class="dropdown-item size16400" onClick={() => handleSelectDate(item)} style={{ padding: '9px', cursor: 'pointer' }}>{item.label}</a>
                      {index + 1 !== dateOptions.length ? <div className="dropdown-divider"></div> : ''}
                    </>
                  ))}
                </div>
              </div>
              {
                keyDate === "between" ?
                  (
                    <div className='d-flex flex-row align-items-center' style={{ width: '100%' }}>
                      <div className='calendar-box-respone'>
                        <p>{fromFormDateValue ? (moment(fromFormDateValue).format('MM / DD / YYYY')) : 'Select date'}</p>
                        <img onClick={() => setFromFormDateActive(!fromFormDateActive)} style={{ position: 'absolute', right: '9px', top: '12px' }} alt="" src={CalendarIcon} />
                      </div>
                      <div style={{ width: '10px', height: '1px', backgroundColor: 'black', marginLeft: '16px', marginRight: '16px' }}></div>
                      <div className='calendar-box-respone'>
                        <p>{toFormDateValue ? (moment(toFormDateValue).format('MM / DD / YYYY')) : 'Select date'}</p>
                        <img onClick={() => setToFormDateActive(!toFormDateActive)} style={{ position: 'absolute', right: '9px', top: '12px' }} alt="" src={CalendarIcon} />
                      </div>
                    </div>
                  ) : (
                    <div className='calendar-box-respone'>
                      <p>{formDateValue ? (moment(formDateValue).format('MM / DD / YYYY')) : 'Select date'}</p>
                      <img onClick={() => setFormDateActive(!formDateActive)} style={{ position: 'absolute', right: '9px', top: '12px' }} alt="" src={CalendarIcon} />
                    </div>
                  )}
            </div>
            <div ref={refOne}>
              <CalendarCSL activeCalendar={formDateActive} maxDate={true} changeDate={handleChangeDate} date={formDateValue} handleActiveCalendar={() => setFormDateActive(!formDateActive)} />
              <CalendarCSL activeCalendar={toFormDateActive} maxDate={true} changeDate={handleChangeToDate} date={toFormDateValue} handleActiveCalendar={() => setToFormDateActive(!toFormDateActive)} />
              <CalendarCSL activeCalendar={fromFormDateActive} maxDate={true} changeDate={handleChangeFromDate} date={fromFormDateValue} handleActiveCalendar={() => setFromFormDateActive(!fromFormDateActive)} />
            </div>
          </div>
        </div>
        <div class="form-check" style={{ marginTop: '12px' }}>
          <input
            className='input-check'
            type="checkbox"
            id="Other"
            name="newDonors"
            value="otherHistory"
            checked={donationHistoryCheck.otherHistory}
            onClick={handleCheckDonationHistory}
          />
          <label className='size16700' for="Other">
            Other
          </label>
          {
            activeOtherText ? (
              <div style={{ marginTop: '24px' }}>
                <label for="floatingTextarea2Other" class="form-label size14700" style={{ marginBottom: '4px' }}>Other</label>
                <textarea class="form-control custom-input" id="floatingTextarea2Other" value={otherComment} onChange={(e) => setOtherComment(e.target.value)} style={{ height: '100px' }}></textarea>
              </div>
            ) : ''
          }
        </div>
      </div>
    </div>
  )
}

export default SelectDonationHistory;