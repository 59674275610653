import React, { useState, setState, state, useEffect, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DonorType from '../components/DonorType';
import SelectDonationHistory from '../components/SelectDonationHistory';
import CheckedYesBox from '../components/CheckedYesBox';
import AddKeyMessage from '../components/AddKeyMessage';
import { BrowserRouter, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Axios } from 'axios';

import { isCompositeComponentWithType } from 'react-dom/test-utils';
import useForm from '../useForm';
// import DonationHistoryCalendar from './DonationHistoryCalendar';
import DatePickerCalendar from '../components/DatePickerCalendar';
import CheckedOtherCommentBox from '../components/CheckedOtherCommentBox';
import ReCaptcha from '../config/ReCaptcha';
import UploadFiles from '../components/UploadFiles';
import AdditionalCommentBox from '../components/AdditionalCommentBox';
import LocationCenter from '../components/LocationCenter';
import LoginForm from '../components/LoginForm';
import { CalendarCSL } from '../container/calendar/CalendarCSL';
import CalendarIcon from '../assets/images/icons/calendar-icon.svg'
import CampaignRun from '../container/campaignRun';
import KeyMessage from '../container/keyMessage';
import API from '../utils/request'
import { INTAKE_FORM, INTAKE_FORM_EDIT, UPLOAD_FILE } from '../api/URLs';

import "../styles/intakeForm.css"
import StakeHolder from '../components/stakeholder/stakeHolder';
import CampaignDetails from '../components/campaignDetails/campaignDetails';
import Audience from '../components/audience/audience';
import Content from '../components/Content';
import Design from '../components/Design';
import CampaignMandatories from '../components/CampaignMandatories';
import CampaignKPIs from '../components/CampaignKPIs';
import { connect } from 'react-redux';
import { formatDateAPI, formatDateAPI2, FeedESTDateToString, formatDateString } from '../functions/format/dateFormat';
import LoadingOverlay from '../container/loading/LoadingOverlay';
import { validationSubmitForm } from '../functions/validation';
import MessageModalMain from '../container/modal/MessageModalMain';
import { COMPAIGN_BY_ID } from "../api/URLs";
import moment from 'moment';
import PageNotFound from './pageNotFound';

const IntakeFormEdit = (props) => {
    const { user } = props;

    const messageModalRef = useRef('rememberMe');
    const [loading, setLoading] = useState(false)
    const [dataEnity, setDataEnity] = useState(false);
    const [id, setId] = useState('')
    const [disabledButton, setDisabledButton] = useState(true)
    const [isSubmit, setisSubmit] = useState(false)
    //Stakeholder Value
    const [emailTest, setEmailTest] = useState()
    //Campaign Details Value
    const [campaignTitle, setCampaignTitle] = useState()
    const [campaignGoal, setCampaginGoal] = useState()
    const [dateFrom, setDateFrom] = useState()
    const [dateTo, setDateTo] = useState()
    //Audience Value
    const [targetAudience, settargetAudience] = useState([])
    const [donorType, setDonorType] = useState([])
    const [selectedDonorTypes, setSelectedDonorTypes] = useState([]);
    const [donorTypeOtherComment, setDonorTypeOtherComment] = useState()
    const [initialLabel, setInitialLabel] = useState()
    const [secondLabel, setSecondLabel] = useState()
    const [thirdLabel, setThirdLabel] = useState()

    //Donation History
    const [historyOption, setHistoryOption] = useState()
    const [donationHistory, setDonationHistory] = useState({
        "noDonation": "no",
        "donationAmount": "no",
        "donationDate": "no",
        "otherHistory": "no"
    })

    const [donorHistory, setDonorHistory] = useState([])
    const [checkDonation, setCheckDonation] = useState()
    const [checkAmount, setCheckAmount] = useState()

    const [donationAmount, setDonationAmount] = useState([])
    const [dateAmount, setDateAmount] = useState([])

    const [valueAmount, setValueAmount] = useState([])
    const [keyAmount, setKeyAmount] = useState()
    const [keyDate, setKeyDate] = useState()
    const [checkDate, setCheckDate] = useState()
    const [donationDate, setDonationDate] = useState()
    const [checkOther, setCheckOther] = useState()
    const [otherComment, setOtherComment] = useState()

    const [checkAllLocation, setCheckAllLocation] = useState('no')
    const [selectedState, setSelectedState] = useState([])
    const [selectedCities, setSelectedCities] = useState([])
    const [selectedCenter, setSelectedCenter] = useState([])
    const [locationCheckOther, setLocationCheckOther] = useState()
    const [locationOtherComment, setLocationOtherComment] = useState()

    const [campaignRunmore, setCampaignRunmore] = useState([])

    const [donorLocation, setDonorLocation] = useState([])
    const [audienceExceptions, setAudienceExceptions] = useState()
    const [totalApproximate, setTotatApproximate] = useState()
    const [campaignRun, setCampaignRun] = useState([])
    const [retargetConditions, setRetargetConditions] = useState([])
    //Content Value
    const [numCommunications, setNumCommunications] = useState();
    const [addKeyMessage, setAddKeyMessage] = useState([{
        keyMessage: '',
        keyLanguage: '',
        channel: [
            { "type": "email", "isCheck": "no" },
            { "type": "sms", "isCheck": "no" },
            { "type": "app", "isCheck": "no" }
        ],
        errMesage: '',
        errChannel: ''
    }])
    const [keyMessage, setKeyMessage] = useState()
    // const [channels, setChannels] = useState([])
    const [personalization, setPersonalization] = useState([{
        dynamicContents: [
            { "type": "First Name", "isCheck": "no" },
            { "type": "Last Donation Center", "isCheck": "no" },
            { "type": "Other", "isCheck": "no" }
        ],
        additionalText: ''
    }])
    //Design Value
    const [styleGuide, setStyleGuide] = useState()
    const [newContent, setNewContent] = useState()
    const [otherNewContent, setOtherNewContent] = useState()
    const [existingContent, setExistingContent] = useState()
    const [specifyCampaign, setSpecifyCampaign] = useState()
    const [contentApplied, setContentApplied] = useState()
    const [hasCampaign, setHasCampaign] = useState()
    const [listPeople, setListPeople] = useState()
    const [contentDesignAsset, setContentDesignAsset] = useState([])
    const [designAssetLeverage, setdesignAssetLeverage] = useState([])
    const [audienceExceptionAsset, setaudienceExceptionAsset] = useState([])
    const [designOther, setdesignOther] = useState({
        "other": "no",
        "additionalText": ""
    })
    //Campaign Mandatories
    const [campaignOffer, setCampaignOffer] = useState()
    const [legalDislaimer, setLegalDislaimer] = useState()
    const [campaignKPI, setCampaginKPI] = useState()
    const [specifyLegalDeclaimer, setSpecifyLegalDeclaimer] = useState()
    const [haveApproved, setHaveApproved] = useState({
        "title": "has this been approved by legal?",
        "type": "",//Yes
        "isCheck": "", //yes
        "insertName": "",
        "isConfirm": false, // true== checked, false=no check
    })
    //Campaign KPIs
    const [campaignObject, setCampaignObject] = useState({
        reportRequest: {
            desiredMetrics: [],
            comment: ''
        },
        campaignStatus: '',
        startDate: '',
        endDate: '',
        additionalComment: '',
    });
    const [metricsRequired, setMetricsRequired] = useState()
    const [updateStatus, setUpdateStatus] = useState()
    const [additionalComments, setAdditionalComments] = useState()
    const [captchaToken, setCaptchaToken] = useState()

    const [toActive, setToActive] = useState(false)
    const [fromActive, setFromActive] = useState(false)
    const [locationAll, setLocationAll] = useState("")
    const [locationAllPR, setLocationAllPR] = useState("")

    //comment
    const [comment, setComment] = useState({
        journeyGoalConfiguration: '',
        journeyAudienceEntryCriteria: '',
        dataPointsHealthCloud: '',
        reEnterJourneyRequirements: '',
        sFMCFolder: '',
        sMSPushActivitiesSettings: '',
        adminAdditionalComment: '',
    });
    const [status, setStatus] = useState('pending')


    const [formData, setFormData] = useState({
        title: '',
        goal: '',
        target_audience: [],
        channels: [],
        approved_legal: [],
        approved_people: '',
        approved_budget: '',
        required_disclaimer: '',
        success_metrics: '',
        status_updates: ''
    })

    const [formError, setFormError] = useState({});
    const onChangeHandler = (event) => {
        if (event.target.name === 'channels') {
            let copy = { ...formData }
            if (event.target.checked) {
                copy.channels.push(event.target.value)
            } else {
                copy.channels = copy.channels.filter(el => el !== event.target.value)
            }
            setFormData(copy)

        } else {
            setFormData(() => ({
                ...formData,
                [event.target.name]: event.target.value
            }))
        }
    }

    const validateKeyMessage = () => {
        let countValid = 0 ;
        addKeyMessage.map(it => {
            if(!it.keyMessage){
                countValid ++;
                it.errMesage = "Mesage required!";
            }
            let i = 0;
            it.channel.map(ch=> {
                if(ch.isCheck === 'no'){
                    i++;
                }
            })
            if(i === it.channel.length){
                countValid++;
                it.errChannel = "Select one required!";
            }
        });
        if(countValid > 0){
            return true;
        }
        return false;
    }
    
    const validateForm = () => {
        let error = {}
        if (formData.title === '') {
            error.title = 'Field required!'
        }
        if (formData.goal === '') {
            error.goal = 'Field required!'
        }
        if (formData.target_audience === '') {
            error.target_audience = 'Select one required!'
        }
        if (formData.channels.length < 1) {
            error.channels = 'Select one required!'
        }
        if (formData.approved_legal.length < 1) {
            error.approved_legal = 'Select one required!'
        }
        if (formData.approved_people === '') {
            error.approved_people = 'Field required!'
        }
        if (formData.approved_budget.length < 1) {
            error.approved_budget = 'Select one required!'
        }
        if (formData.required_disclaimer.length < 1) {
            error.required_disclaimer = 'Select one required!'
        }
        if (formData.success_metrics === '') {
            error.success_metrics = 'Field required!'
        }
        if (formData.status_updates.length < 1) {
            error.status_updates = 'Field required!'
        }

        setFormError({ ...error })

        return Object.keys(error).length < 1;
    }

    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState({});
    const [dateRangeError, setDateRangeError] = useState({})

    const [token, setToken] = useState(null);

    const checkAudienceType = () => {
        let typeArr = []
        let audienceTypesArr = [];
        if (initialLabel) {
            typeArr.push(initialLabel)
        }
        if (secondLabel) {
            typeArr.push(secondLabel)
        }
        if (thirdLabel) {
            typeArr.push(thirdLabel)
        }
        typeArr.forEach(it => {
            switch (it) {
                case 'Donor Type':
                    audienceTypesArr.push({
                        "inputType": "donor-type",
                        "donorType": donorType,
                        "additionalText": donorType.find(it => it === 'Other') ? donorTypeOtherComment : ''
                    })
                    break;
                case 'Donation History':
                    audienceTypesArr.push({
                        "inputType": "donation-history",
                        "noDonation": donationHistory.noDonation,
                        "donationAmount": {
                            "enable": donationHistory.donationAmount,
                            "key": keyAmount && donationHistory.donationAmount == 'yes' ? keyAmount : "",
                            "value": donationHistory.donationAmount === 'yes' ? donationAmount : '',
                        },
                        "donationDate": {
                            "enable": donationHistory.donationDate,
                            "key": keyDate && donationHistory.donationDate === 'yes' ? keyDate : "",
                            "value": donationHistory.donationDate ? dateAmount : []
                        },
                        "other": donationHistory.otherHistory,
                        "additionalText": donationHistory.otherHistory === 'yes' ? otherComment : '',
                    })
                    break;
                case 'Location':
                    audienceTypesArr.push({
                        "inputType": "location",
                        "allCenter": checkAllLocation,
                        "typeCenter": locationAll,
                        "typePrCenter":locationAllPR,
                        "states": checkAllLocation === 'no' ? selectedState : '',
                        "city": checkAllLocation === 'no' ? selectedCities : '',
                        "centers": checkAllLocation === 'no' ? selectedCenter : '',
                        "other": locationCheckOther ? locationCheckOther: 'no',
                        "additionalText": locationCheckOther === 'yes' ? locationOtherComment : ''
                    })
                    break;
            }
        });
        return audienceTypesArr;
    }
    const setAudienceTypes = (targetAudience) => {
        let typeArr = []
        let audienceTypesArr = [];
        targetAudience.forEach((it, index) => {
            switch (it.inputType) {
                case 'donor-type':
                    if(index === 0 ) {
                        setInitialLabel('Donor Type')
                    } else if(index == 1) {
                        setSecondLabel('Donor Type')
                    } else {
                        setThirdLabel('Donor Type')
                    }
                    setDonorType(it.donorType)
                    setDonorTypeOtherComment(it.additionalText)
                    break;
                case 'donation-history':
                    if(index === 0 ) {
                        setInitialLabel('Donation History')
                    } else if(index == 1) {
                        setSecondLabel('Donation History')
                    } else {
                        setThirdLabel('Donation History')
                    }
                    setKeyAmount(it.donationAmount.key)
                    setKeyDate(it.donationDate.key)
                    setOtherComment(it.additionalText)
                    setDonationHistory({
                        "noDonation": it.noDonation ? it.noDonation : "no",
                        "donationAmount": it.donationAmount ? it.donationAmount.enable : "no",
                        "donationDate": it.donationDate ? it.donationDate.enable : "no",
                        "otherHistory": it.other ? it.other : "no"
                    })
                    setDonationAmount([it.donationAmount.value[0], it.donationAmount.value[1]])
                    setDateAmount([it.donationDate.value[0], it.donationDate.value[1]])
                    break;
                case 'location':
                    if(index === 0 ) {
                        setInitialLabel('Location')
                    } else if(index == 1) {
                        setSecondLabel('Location')
                    } else {
                        setThirdLabel('Location')
                    }
                    setCheckAllLocation(it.allCenter)
                    if(it.allCenter === 'yes') {
                        if (!it.typeCenter && !it.typePrCenter) {
                            setLocationAll("all_us_centers")
                        } else {
                            if (it.typePrCenter === 'all_pr_centers') {
                                setLocationAllPR("all_pr_centers")
                            }
                            if (it.typeCenter === 'all_us_centers') {
                                setLocationAll("all_us_centers")
                            }
                        }
                    }
                    setSelectedState(it.states)
                    setSelectedCities(it.city)
                    setSelectedCenter(it.centers)
                    setLocationCheckOther(it.other)
                    setLocationOtherComment(it.additionalText)
                    break;
            }
        });
        return audienceTypesArr;
    }

    const [ validAud, setValidAud ] = useState({
        initialLabel : false,
        secondLabel : false,
        thirdLabel : false
    })
    const validateAudience = async () => {
        let valid = {
            initialLabel : false,
            secondLabel : false,
            thirdLabel : false
        }
        let countValid = 0 ;
        let typeArr = []
        if (initialLabel) {
            typeArr.push(initialLabel)
        }
        if (secondLabel) {
            typeArr.push(secondLabel)
        }
        if (thirdLabel) {
            typeArr.push(thirdLabel)
        }
        if(typeArr.length === 0) {
            valid.initialLabel = true
            setValidAud(valid)
            return true
        }
        typeArr.forEach((it, index) => {
            switch (it) {
                case 'Donor Type':
                    if(!donorType.length) {
                        countValid ++
                        if(index === 0 ) {
                            valid.initialLabel = true
                        } else if(index == 1) {
                            valid.secondLabel = true
                        } else {
                            valid.thirdLabel = true
                        }
                    }
                    break;
                case 'Donation History':
                    if(donationHistory.donationAmount === 'yes') {
                        if(keyAmount === 'less-then' || keyAmount === 'greater-then') {
                            if(donationAmount[0] === null || donationAmount[0] === '' ) {
                                countValid ++
                                if(index === 0 ) {
                                    valid.initialLabel = true
                                } else if(index == 1) {
                                    valid.secondLabel = true
                                } else {
                                    valid.thirdLabel = true
                                }
                            }
                        } else {
                            if(donationAmount[0] === null || donationAmount[1] === null  || donationAmount[0] === ''  || donationAmount[1] === '' ) {
                                countValid ++
                                if(index === 0 ) {
                                    valid.initialLabel = true
                                } else if(index == 1) {
                                    valid.secondLabel = true
                                } else {
                                    valid.thirdLabel = true
                                }
                            }
                        }
                    }
                    if(donationHistory.donationDate == 'yes'){
                        if(keyDate === 'before' || keyDate === 'after') {
                            if(dateAmount[0] === null || dateAmount[0] === '') {
                                countValid ++
                                if(index === 0 ) {
                                    valid.initialLabel = true
                                } else if(index == 1) {
                                    valid.secondLabel = true
                                } else {
                                    valid.thirdLabel = true
                                }
                            }
                        } else {
                            if(dateAmount[0] === null || dateAmount[1] === null || dateAmount[0] === '' || dateAmount[1] === '' ) {
                                countValid ++
                                if(index === 0 ) {
                                    valid.initialLabel = true
                                } else if(index == 1) {
                                    valid.secondLabel = true
                                } else {
                                    valid.thirdLabel = true
                                }
                            }
                        }
                    }
                    if(donationHistory.noDonation != 'yes' &&  donationHistory.donationAmount != 'yes' && donationHistory.donationDate != 'yes' ) {
                        countValid ++
                        if(index === 0 ) {
                            valid.initialLabel = true
                        } else if(index == 1) {
                            valid.secondLabel = true
                        } else {
                            valid.thirdLabel = true
                        }
                    }
                    break;
                case 'Location':
                    if( checkAllLocation != 'yes' && selectedState.length === 0 && selectedCities.length === 0 && selectedCenter.length === 0 ) {
                        countValid ++
                        if(index === 0 ) {
                            valid.initialLabel = true
                        } else if(index == 1) {
                            valid.secondLabel = true
                        } else {
                            valid.thirdLabel = true
                        }
                    }
                    break;
            }
        });
        
        setValidAud(valid)

        if(countValid > 0){
            return true;
        }
        return false;
    }

    useEffect(() => {
        validateAudience()
    }, [donorType])

    useEffect(() => {
        validateAudience()
    }, [donationHistory])

    useEffect(() => {
        validateAudience()    
    }, [checkAllLocation,
        selectedState,
        selectedCities,
        selectedCenter,
        locationCheckOther,
        locationOtherComment])

    const handleSubmitForm = async (e) => {
        e.preventDefault()
        setisSubmit(true);
        let campaignStatus = campaignObject.campaignStatus
        let camOffer = campaignOffer ? (campaignOffer.isCheck === 'yes' ? campaignOffer : 'no') : 'no'
        setError(validationSubmitForm(campaignTitle, campaignGoal, hasCampaign, listPeople, campaignOffer , legalDislaimer, campaignStatus, initialLabel, specifyLegalDeclaimer))

        if(validateKeyMessage() || (!campaignObject.startDate || !campaignObject.endDate) || (!dateFrom || !dateTo)){
            setDateRangeError({
                ...dateRangeError, 
                "dateRange": "Date Range is Required*",
                "campaignDuration": "Campaign Duration is Required*"
            })
            return false;
        }
        if (!campaignStatus) {
            return false
        }
        if (!campaignTitle) {
            return false
        }

        if (!campaignGoal) {
            return false
        }

        if (!hasCampaign) {
            return false
        }

        if ( hasCampaign.find(item => item.type === 'Yes') && !listPeople) {
            return false
        }

        if (!campaignOffer) {
            if (campaignOffer.isCheck === 'yes') {
                return false
            }
        }

        if (!legalDislaimer) {
            return false
        }
        if ( legalDislaimer.find(item => item.type === 'Yes')) {
            if(haveApproved.isCheck === "no" && !haveApproved.isConfirm) {
                return false
            }
            if(haveApproved.isCheck === "yes" && !haveApproved.insertName ) {
                return false
            }
        }

        // if (!token) {
        //     return false
        // }

        let validAud = await validateAudience()
        if(validAud) {
            return false;
        }
        
        let reportRequest = campaignObject.reportRequest
        reportRequest.comment = reportRequest.desiredMetrics.find(it => it.isCheck === 'yes' && it.type === 'Other') ? reportRequest.comment : ''
        var param = {
            "campaignId": id,
            "emailTesting": emailTest ? emailTest : "",
            "title": campaignTitle ? campaignTitle : "",
            "goal": campaignGoal ? campaignGoal : "",
            "startDuration": dateFrom ? formatDateAPI2(dateFrom) : "",
            "endDuration": dateTo ? formatDateAPI2(dateTo) : "",
            "targetAudience": await checkAudienceType(),
            "audienceException": audienceExceptions ? audienceExceptions : "",
            "audienceExceptionAsset": audienceExceptionAsset,
            "totalApproximate": totalApproximate,
            "campaignRunmore": campaignRunmore,
            "numberCommunications": 10,
            "addKeyMessage": addKeyMessage.map(it => ({
                keyMessage: it.keyMessage, 
                keyLanguage: it.keyLanguage ? it.keyLanguage : '', 
                channel: it.channel.map(ch => ({ "type": ch.type, "isCheck": ch.isCheck }))
            })),
            "retargetConditions": retargetConditions.length > 0 ? retargetConditions : '',
            "personalization": personalization,
            "styleGuide": styleGuide,
            "contentDesignAsset": contentDesignAsset.map(it => ( {
                keyLanguage: it.keyLanguage,
                files: it.files
            })),
            "designOther": designOther,
            "designAssetLeverage": designAssetLeverage.map(it => ( {
                keyLanguage: it.keyLanguage,
                files: it.files
            })),
            "specifyCampaignAsset": specifyCampaign,
            "contentApply": contentApplied,
            "campaignIdea": hasCampaign,
            "peopleApprove": hasCampaign ? hasCampaign.find(it => it.isCheck === 'yes') ? listPeople : '' : '',
            "campaignOffer": campaignOffer,
            "legalDeclaimer": legalDislaimer,
            "haveApproved": haveApproved,
            "campaignKpi": campaignKPI,
            "reportRequest": reportRequest,
            "campaignStatus": campaignObject.campaignStatus,
            "startDate": campaignObject.startDate,
            "endDate": campaignObject.endDate,
            "status": status,

            "additionalComment": campaignObject.additionalComment,
            "journeyGoalConfiguration": comment.journeyGoalConfiguration ,
            "journeyAudienceEntryCriteria": comment.journeyAudienceEntryCriteria ,
            "dataPointsHealthCloud": comment.dataPointsHealthCloud ,
            "reEnterJourneyRequirements": comment.reEnterJourneyRequirements ,
            "sFMCFolder": comment.sFMCFolder ,
            "sMSPushActivitiesSettings": comment.sMSPushActivitiesSettings ,
            "adminAdditionalComment": comment.adminAdditionalComment ,

            // "gReCaptchaResponse": token
        }

        setLoading(true)

        API.post(INTAKE_FORM_EDIT, param)
            .then((res) => {
                setLoading(false)
                if (res.status === 1) {
                    messageModalRef.current.showSuccess(res.message, () => {
                        window.location.href = '/submitFormList'
                    });
                } else {
                    messageModalRef.current.showError(res.message);
                }
            })
            .catch((err) => {
                setLoading(false)
                if(typeof err.message === 'string'){
                    messageModalRef.current.showError(err.message);
                }
                console.log(err);
            })
    }

    const handleUploadFile = (fileName, filebase64, type, setNewContentLoading, setExistingContentLoading, i) => {
        var param = {
            "filename": fileName,
            "fileAsset": filebase64
        }
        if(type === 'contentDesignAsset'){
            setNewContentLoading(true)
            contentDesignAsset[i].newContentLoading = true
            setContentDesignAsset([...contentDesignAsset])
        }
        if(type === 'designAssetLeverage'){
            setExistingContentLoading(true)
            designAssetLeverage[i].existingContentLoading = true
            setdesignAssetLeverage([...designAssetLeverage])
        }
        if(type === 'audienceExceptionAsset'){
            setExistingContentLoading(true)
        }

        API.post(UPLOAD_FILE, param)
            .then((res) => {
                setNewContentLoading(false)
                setExistingContentLoading(false)
                if (res.result) {
                    if (type === 'contentDesignAsset') {
                        contentDesignAsset[i].newContentLoading = false
                        contentDesignAsset[i].files.push({"url": res.url})
                        setContentDesignAsset([...contentDesignAsset])
                    } else if (type === 'audienceExceptionAsset') {
                        audienceExceptionAsset.push({"url": res.url})
                        setaudienceExceptionAsset([...audienceExceptionAsset])
                    } else {
                        designAssetLeverage[i].existingContentLoading = false
                        designAssetLeverage[i].files.push({"url": res.url})
                        setdesignAssetLeverage([...designAssetLeverage])
                    }
                }else if(res.result === false) {
                    messageModalRef.current.showWarning(res?.message);
                }
            })
            .catch((err) => {
                setNewContentLoading(false)
                setExistingContentLoading(false)
                console.log(err);
                if(type === 'contentDesignAsset') {
                    contentDesignAsset[i].newContentLoading = false
                    setContentDesignAsset([...contentDesignAsset])
                }
                if(type === 'designAssetLeverage'){
                    designAssetLeverage[i].existingContentLoading = false
                    setdesignAssetLeverage([...designAssetLeverage])
                }
            })
    }


    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if (searchParams.get('cam')) {
            fetchViewByID(searchParams.get('cam'));
        } else {
            console.log("Fail!")
            setDataEnity(true);
        }
    }, [])

    const fetchViewByID = (id) => {
        setLoading(true)
        var params = {
            "campaignId": id
        }

        API.post(COMPAIGN_BY_ID, params)
            .then((res) => {
                setLoading(false)
                if (res.status === 1) {
                    setLoading(false)
                    let data = res.data;
                    if (data) {
                        setId(id)
                        const { 
                            emailTesting,
                            title,
                            goal,
                            startDuration,
                            endDuration,
                            targetAudience,
                            audienceException,
                            audienceExceptionAsset,
                            totalApproximate,
                            campaignRunmore,
                            numberCommunications,
                            addKeyMessage,
                            retargetConditions,
                            personalization,
                            styleGuide,
                            contentDesignAsset,
                            designOther,
                            designAssetLeverage,
                            specifyCampaignAsset,
                            contentApply,
                            campaignIdea,
                            peopleApprove,
                            campaignOffer,
                            legalDeclaimer,
                            specifyLegalDeclaimer,
                            campaignKpi,
                            reportRequest,
                            campaignStatus,
                            startDate,
                            endDate,
                            status,
                            additionalComment,
                            journeyGoalConfiguration,
                            journeyAudienceEntryCriteria,
                            dataPointsHealthCloud,
                            reEnterJourneyRequirements,
                            sFMCFolder,
                            sMSPushActivitiesSettings,
                            adminAdditionalComment,
                            haveApproved,
                        } = data;
                        setEmailTest(emailTesting)
                        setCampaignTitle(title)
                        setCampaginGoal(goal)
                        if(startDuration) { let fromD =  formatDateString(startDuration.slice(0, 10)); setDateFrom(new Date(fromD))}
                        if(endDuration){ let endD = formatDateString(endDuration.slice(0, 10));  setDateTo(new Date(endD)) }
                        setAudienceTypes(targetAudience)
                        settargetAudience(targetAudience)
                        setAudienceExceptions(audienceException)
                        setaudienceExceptionAsset(audienceExceptionAsset)
                        setTotatApproximate(totalApproximate)
                        setCampaignRunmore(campaignRunmore)
                        setAddKeyMessage(addKeyMessage.map(it => ({...it, keyLanguage: it.keyLanguage ? it.keyLanguage : 'English'})))
                        setRetargetConditions(retargetConditions)
                        setPersonalization(personalization)
                        setStyleGuide(styleGuide)

                        let contentDesignAssetNew = contentDesignAsset.length > 0 ? contentDesignAsset.filter(it => it.url) : []
                        let contentDesignAssetData = contentDesignAssetNew.length > 0 ? [
                            {
                                "keyLanguage": "English",
                                "files": contentDesignAssetNew
                            }
                        ] : contentDesignAsset && contentDesignAsset.length > 0 ? contentDesignAsset : [ {
                            "keyLanguage": "",
                            "files": []
                        }]
                        setContentDesignAsset(contentDesignAssetData)

                        setdesignOther(designOther)

                        let designAssetLeverageNew = designAssetLeverage.length > 0 ? designAssetLeverage.filter(it => it.url) : []
                        let designAssetLeverageData = designAssetLeverageNew.length > 0 ? [
                            {
                                "keyLanguage": "English",
                                "files": contentDesignAssetNew
                            }
                        ] : designAssetLeverage && designAssetLeverage.length > 0 ? designAssetLeverage : [ {
                            "keyLanguage": "",
                            "files": []
                        }]
                        setdesignAssetLeverage(designAssetLeverageData)

                        setSpecifyCampaign(specifyCampaignAsset)
                        setContentApplied(contentApply)
                        setHasCampaign(campaignIdea)
                        setListPeople(peopleApprove)
                        setCampaignOffer(campaignOffer)
                        setLegalDislaimer(legalDeclaimer)
                        setSpecifyLegalDeclaimer(specifyLegalDeclaimer)
                        setHaveApproved(haveApproved ? haveApproved : {
                            "title": "has this been approved by legal?",
                            "type": "",//Yes
                            "isCheck": "", //yes
                            "insertName": "",
                            "isConfirm": false, // true== checked, false=no check
                        })
                        setCampaginKPI(campaignKpi)
                        setCampaignObject({
                            reportRequest: reportRequest,
                            campaignStatus: campaignStatus,
                            startDate: startDate ? startDate.slice(0, 10) : '',
                            endDate: endDate ? endDate.slice(0, 10) : '',
                            additionalComment: additionalComment
                        });
                        setComment({
                            journeyGoalConfiguration: journeyGoalConfiguration,
                            journeyAudienceEntryCriteria: journeyAudienceEntryCriteria,
                            dataPointsHealthCloud: dataPointsHealthCloud,
                            reEnterJourneyRequirements: reEnterJourneyRequirements,
                            sFMCFolder: sFMCFolder,
                            sMSPushActivitiesSettings: sMSPushActivitiesSettings,
                            adminAdditionalComment: adminAdditionalComment,
                        })
                    }
                } else {
                    console.log("Fail!");
                    setDataEnity(true)
                }
            })
            .catch((err) => {
                console.log(err);
                setDataEnity(true);
                setLoading(false)
            })
    }
    if (dataEnity) {
        return <PageNotFound />
    }

    return (
        <div className="main-form d-flex flex-column align-items-center">
            {loading ? <LoadingOverlay /> : null}
            <MessageModalMain textCentered ref={messageModalRef} />
            <form className='form-group' onSubmit={handleSubmitForm}>
                <div className='d-flex flex-column align-items-center justify-content-center' style={{ marginBottom: '50px' }}>
                    <h2 className='size30700' style={{ marginBottom: '8px' }}>Marketing Campaign Intake Form</h2>
                    <p className='size16400 mb-0'>Please complete the form to request a marketing campaign journey.</p>
                    <p className='size14520 mb-0'>Please be advised: Digital campaigns take approximately 2 weeks from approval to deployment</p>
                </div>
                <div>
                    <StakeHolder
                        user={user}
                        setEmailTest={setEmailTest}
                        emailTest={emailTest}
                    />
                    <div className="csl-red-line"></div>
                    <CampaignDetails
                        validateFields={error}
                        setCampaignTitle={setCampaignTitle}
                        campaignTitle={campaignTitle}
                        setDateFrom={setDateFrom}
                        dateFrom={dateFrom}
                        setDateTo={setDateTo}
                        dateTo={dateTo}
                        campaignGoal={campaignGoal}
                        setCampaginGoal={setCampaginGoal}
                        dateRangeError={dateRangeError ? dateRangeError.campaignDuration : null}
                    />
                    <div className="csl-red-line"></div>
                    <Audience
                        submits={{isSubmit, setisSubmit}}
                        validAuds={{validAud, setValidAud, validateAudience}}
                        targetAudience={targetAudience}
                        validateFields={error}
                        setDonorType={setDonorType}
                        donorType={donorType}
                        setDonorTypeOtherComment={setDonorTypeOtherComment}
                        donorTypeOtherComment={donorTypeOtherComment}
                        locationAll={locationAll} 
                        setLocationAll={setLocationAll}
                        locationAllPR={locationAllPR} 
                        setLocationAllPR={setLocationAllPR}

                        //case 2
                        keyAmount={keyAmount}
                        setKeyAmount={setKeyAmount}
                        setKeyDate={setKeyDate}
                        keyDate={keyDate}
                        setOtherComment={setOtherComment}
                        otherComment={otherComment}
                        setDonationHistory={setDonationHistory}
                        donationHistory={donationHistory}
                        donationAmount={donationAmount}
                        dateAmount={dateAmount}

                        setDonationAmount={setDonationAmount}
                        setDateAmount={setDateAmount}

                        //case 3
                        setCheckAllLocation={setCheckAllLocation}
                        checkAllLocation={checkAllLocation}
                        setSelectedState={setSelectedState}
                        selectedState={selectedState}
                        setSelectedCities={setSelectedCities}
                        selectedCities={selectedCities}
                        setSelectedCenter={setSelectedCenter}
                        selectedCenter={selectedCenter}
                        setLocationCheckOther={setLocationCheckOther}
                        locationCheckOther={locationCheckOther}
                        setLocationOtherComment={setLocationOtherComment}
                        locationOtherComment={locationOtherComment}

                        selectedTypes={{
                            selectedDonorTypes, setSelectedDonorTypes,
                            initialLabel, setInitialLabel, secondLabel, setSecondLabel,
                            thirdLabel, setThirdLabel
                        }}
                        audienceExceptions={audienceExceptions}
                        setAudienceExceptions={setAudienceExceptions}
                        setTotatApproximate={setTotatApproximate}
                        totalApproximate={totalApproximate}
                        retargetConditions={retargetConditions}
                        setRetargetConditions={setRetargetConditions}
                        campaignRunmore={campaignRunmore}
                        setCampaignRunmore={setCampaignRunmore}
                        uploadeFile={{
                            handleUploadFile,
                            audienceExceptionAsset, setaudienceExceptionAsset,
                        }}
                    />
                    <div className="csl-red-line"></div>
                    <Content
                        addKeyMessage={addKeyMessage}
                        setAddKeyMessage={setAddKeyMessage}
                        personalization={personalization}
                        setPersonalization={setPersonalization}
                        submits={{isSubmit, setisSubmit}}
                    />
                    <div className="csl-red-line"></div>
                    <Design
                        validateFields={error}
                        setStyleGuide={setStyleGuide}
                        styleGuide={styleGuide}
                        setSpecifyCampaign={setSpecifyCampaign}
                        specifyCampaign={specifyCampaign}
                        setContentApplied={setContentApplied}
                        contentApplied={contentApplied}
                        setHasCampaign={setHasCampaign}
                        hasCampaign={hasCampaign}
                        setListPeople={setListPeople}
                        listPeople={listPeople}
                        uploadeFile={{
                            handleUploadFile, contentDesignAsset,
                            setContentDesignAsset, designAssetLeverage,
                            setdesignAssetLeverage, designOther, setdesignOther
                        }}
                    />
                    <div className="csl-red-line"></div>
                    <CampaignMandatories
                        validateFields={error}
                        campaignKPI={campaignKPI}
                        setCampaginKPI={setCampaginKPI}
                        setCampaignOffer={setCampaignOffer}
                        campaignOffer={campaignOffer}
                        legalDislaimer={legalDislaimer}
                        setLegalDislaimer={setLegalDislaimer}
                        specifyLegalDeclaimer={specifyLegalDeclaimer}
                        setSpecifyLegalDeclaimer={setSpecifyLegalDeclaimer}
                        haveApproved={haveApproved} setHaveApproved={setHaveApproved}
                        isSubmit={isSubmit}
                    />
                    <div className="csl-red-line"></div>
                    {/* {
                        hasCampaign ?
                            hasCampaign.map((item) =>

                                item.isCheck === "yes" ? <>
                                    <CampaignMandatories
                                        validateFields={error}
                                        campaignKPI={campaignKPI}
                                        setCampaginKPI={setCampaginKPI}
                                        setCampaignOffer={setCampaignOffer}
                                        campaignOffer={campaignOffer}
                                        legalDislaimer={legalDislaimer}
                                        setLegalDislaimer={setLegalDislaimer}
                                        specifyLegalDeclaimer={specifyLegalDeclaimer}
                                        setSpecifyLegalDeclaimer={setSpecifyLegalDeclaimer}
                                    />
                                    <div className="csl-red-line"></div>
                                </> : null)
                            : null
                    } */}
                    <CampaignKPIs
                        campaign={{ campaignObject, setCampaignObject }}
                        dateRangeError={dateRangeError ? dateRangeError.dateRange : null}
                        validateFields={error}
                    />
                </div>
                {/* <div className="required">
                    <ReCaptcha
                        setToken={setToken}
                        setDisabledButton={setDisabledButton}
                    />
                    <p className="required-fields">Required Fields *</p>
                </div> */}
                <div style={{ marginTop: "40px" }}>
                    <button
                        className="button_auth button_body"
                        onSubmit={() => handleSubmitForm()}
                        // style={{ opacity: disabledButton ? 0.2 : '', }}
                        // disabled={disabledButton}
                    >
                        <p
                            style={{
                                fontWeight: "800",
                                fontSize: "16px",
                                marginBottom: "0",
                                color: "white",
                            }}
                        >
                            Submit
                        </p>
                    </button>
                </div>
            </form>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mapStateToProps = state => ({
    user: state.authReducer.user,
    token: state.authReducer.token
})

export default (connect(mapStateToProps, mapDispatchToProps)(IntakeFormEdit));