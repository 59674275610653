import { useState } from "react"
import DropupIcon from '../assets/images/icons/dropup-icon.svg'
import DropdownIcon from '../assets/images/icons/dropdown-icon.svg'

const LanguageComponent = (props) => {
    const { addKeyMessage, item, handleSelectlangs, keyLanguage, setKeyLanguage, marginLeft } = props
    const [dateActive, setDateActive] = useState(false)
    // const [keyLanguage, setKeyLanguage] = useState("")

    const [languages, setLanguages] = useState([
        {
            label: "English",
            value: "English"
        },
        {
            label: "Spanish- Mexico",
            value: "Spanish- Mexico"
        },
        {
            label: "Spanish- Puetro Rico",
            value: "Spanish- Puetro Rico"
        },
    ])
    
    const handleSelectlang = (item) => {
        setKeyLanguage(item.value)
        setDateActive(false)
    }


    return <div style={{ position: 'relative', marginLeft: marginLeft ? marginLeft : "5px" }}>
        <button
            style={{
                height: '50px',
                width: '215px',
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '5px',
                padding: '9px',
                position: 'relative',
                marginRight: '16px'
            }}
            onClick={(e) => {
                e.preventDefault();
                setDateActive(!dateActive)
            }}
            className='d-flex flex-row align-items-center'
        >
            <p className="size16600 mb-0">{keyLanguage ? keyLanguage : 'Select Language'}</p>
            <img style={{ position: 'absolute', right: '9px', top: '18px' }} alt="" src={dateActive ? DropupIcon : DropdownIcon} width='18px' />
        </button>
        <div className={`cus-width-drop dropdown-menu pt-0 pb-0 ${dateActive ? 'show' : ''}`} style={{ zIndex: 11 }}>
            {languages && languages.map((item, index) => (
                <>
                    <a href class="dropdown-item size16400" onClick={() => handleSelectlang(item)} style={{ padding: '9px', cursor: 'pointer' }}>{item.label}</a>
                    {index + 1 !== languages.length ? <div className="dropdown-divider"></div> : ''}
                </>
            ))}
        </div>
    </div>
}

export default LanguageComponent;