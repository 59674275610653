import React from 'react'
import Calendar from 'react-calendar'
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import './CalendarCSL.css'
import 'react-calendar/dist/Calendar.css';

export const CalendarCSL = (props) => {
    const { activeCalendar, changeDate, date, handleActiveCalendar, maxDate, minDate, float, view, position, setactiveCalendar, top } = props

    return (
        <div className='row'>
            <div className='col-12' style={{position: 'relative'}}>
                <div style={{ display: `${activeCalendar ? "block" : 'none'}`, float: float, zIndex: 2, position: position ? position : 'absolute', width: '100%', top: top  }}
                    className="dateDrop">
                    <div style={{ float: float }}
                        // onMouseLeave={(e) => {
                        //     if (setactiveCalendar) {
                        //         setactiveCalendar(false);
                        //     }
                        // }} 
                        className='calendarW'>
                        <Calendar
                            onChange={(e) => {
                                changeDate(e)
                                handleActiveCalendar()
                            }}
                            value={date}
                            view={view ? '' : "month"}
                            onDrillUp={() => { }}
                            showNeighboringMonth={false}
                            nextLabel={<RiArrowRightSLine size={40} />}
                            prevLabel={<RiArrowLeftSLine size={40} />}
                            calendarType='US'
                            maxDate={maxDate ? null : new Date()}
                            minDate={minDate ? new Date() : null}
                            style={{ float: float }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}