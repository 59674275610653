import { all } from 'redux-saga/effects';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducers from '../../../reducers';
function* rootSaga() {
  yield all([
  ]);
}

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducers, applyMiddleware(sagaMiddleware));

export { sagaMiddleware, store, rootSaga };
