import moment from 'moment';

//CHECK FOR VALID EMAIL FORMAT
export function validateEmail(email) {
  var re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email.trim()).toLowerCase());
}

export function validateDonorID(donorID) {
  return /^[a-zA-Z0-9]*$/.test(donorID) && donorID.trim().length > 6 && donorID.trim().length < 9;
}

//CHECK FOR VALID PHONE FORMAT
export function validatePhone(phone) {
  return /^\d+$/.test(phone) && phone.length > 8 && phone.length < 12
}

//CHECK FOR NON-NUMBERAL
export function validateCharactersOnly(str) {
  return /^[a-zA-Z()]+$/.test(str) || str === ''
}

export function validateCharacterAndSpaceOnly(str) {
  return /^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(str) || str === ''
}

export function validatePasswordLength(word) {
  if (!word) {
    return false
  } else {
    return word.length >= 8
  }
}
/**
* Check if password has at least one number
* @param {string} word
*/
export function atLeastOneNumber(word) {
  return /\d/.test(word)
}

/**
* Check if password has at least one upper case letter [A-Z]
* @param {string} word
*/
export function atLeastOneUppercase(word) {
  return (/[A-Z]/.test(word))
}

/**
* check if Password has at least one lower case letter [a-z]
* @param {string} word
*/
export function atLeastOneLowercase(word) {
  return (/[a-z]/.test(word))
}

/**
* Check if password matches required requirements.
* @param {string} word
*/
export function validatePassword(password) {
  return validatePasswordLength(password) && atLeastOneNumber(password) && atLeastOneUppercase(password) && atLeastOneLowercase(password)
}

export const validateDate = (date) => {
  date = date.replace(/ /g, '');
  if (date.length !== 10) { return false }
  if (date.includes("/")) {
    date = date.split("/").join("-");
  };
  if (!/^\d+$/.test(date.split("-").join(""))) { return false; };
  var m = moment(date, 'MM-DD-YYYY');
  return m.isValid();
};

export const atLeat18YearsOld = (date) => {
  var removeSpace = date.replace(/ /g, '');
  var validDate = new moment.utc(removeSpace, "MM/DD/YYYY");
  var diff = moment().diff(validDate, 'years');
  return diff >= 18;
}
