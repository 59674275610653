import React, { useEffect, useState } from 'react'
import DropdownComponent from '../container/dropdown/dropdownComponent';

function DonorType(props) {

  const {
    setDonorType,
    donorType,
    setDonorTypeOtherComment,
    donorTypeOtherComment,
    validateAudience
  } = props
  const [otherDonorsChecked, setOtherDonorsChecked] = useState(false);
  const [otherDonorsComment, setOtherDonorsComment] = useState('');

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    newDonors: false,
    applicantDonors: false,
    qualifiedDonors: false,
    lapseDonors: false,
    allDonors: false,
    other: false,
  });

  useEffect(() => {
    if(donorType){
      let type = {
        newDonors: false,
        applicantDonors: false,
        qualifiedDonors: false,
        lapseDonors: false,
        allDonors: false,
        other: false,
      }
      donorType.forEach(it => {
        switch (it) {
          case 'New Donors':
            type.newDonors = true;
            break;
          case 'Applicant Donors':
            type.applicantDonors = true;
            break;
          case 'Qualified Donors':
            type.qualifiedDonors = true;
            break;
          case 'Lapse Donors':
            type.lapseDonors = true;
            break;
          case 'All Donors':
            type.allDonors = true;
            break;
          case 'Other':
            setOtherDonorsChecked(true);
            type.other = true;
          break;
        }
      });
      setSelectedCheckboxes(type)
    }
  }, [donorType])

  useEffect(() => {
    validateAudience()
  }, [otherDonorsChecked,
    selectedCheckboxes])
  
  const handleCheckboxChange = (e) => {
    const { name, value, checked, id } = e.target;
    setSelectedCheckboxes((prevState) => {
      return ({
        ...prevState,
        [name]: !prevState[name],
      })
    });
    if (checked) {
      donorType.push(value)
      setDonorType(donorType)
    } else {
      let it = []
      donorType.forEach(its => {
        if (its != value) {
          it.push(its)
        }
      });
      setDonorType(it)
    }
    if (id === 'other-donors') {
      setOtherDonorsChecked(checked);
    }
  };

  const changeOtherComment = (e) => {
    setDonorTypeOtherComment(e.target.value)
  }


  return (


    <div>
      <p className="size14700" style={{ marginBottom: '12px' }}>Donor Type (please select all that apply)</p>
      <div className='row gx-0 gy-3'>
        <div className='col-12 col-md-4'>
          <div class="form-check">
            <input
              className='input-check'
              type="checkbox"
              id="newDonors"
              name="newDonors"
              value="New Donors"
              checked={selectedCheckboxes.newDonors}
              onChange={handleCheckboxChange} />
            <label className='size16700' for="newDonors">
              New Donors
            </label>
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <div class="form-check">
            <input
              className='input-check'
              type="checkbox"
              id="applicantDonors"
              name="applicantDonors"
              value="Applicant Donors"
              checked={selectedCheckboxes.applicantDonors}
              onChange={handleCheckboxChange} />
            <label className='size16700' for="applicantDonors">
              Applicant Donors
            </label>
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <div class="form-check">
            <input
              className='input-check'
              type="checkbox"
              id="qualifiedDonors"
              name="qualifiedDonors"
              value="Qualified Donors"
              checked={selectedCheckboxes.qualifiedDonors}
              onChange={handleCheckboxChange} />
            <label className='size16700' for="qualifiedDonors">
              Qualified Donors
            </label>
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <div class="form-check">
            <input
              className='input-check'
              type="checkbox"
              id="lapseDonors"
              name="lapseDonors"
              value="Lapse Donors"
              checked={selectedCheckboxes.lapseDonors}
              onChange={handleCheckboxChange} />
            <label className='size16700' for="lapseDonors">
              Lapse Donors
            </label>
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <div class="form-check">
            <input
              className='input-check'
              type="checkbox"
              id="allDonors"
              name="allDonors"
              value="All Donors"
              checked={selectedCheckboxes.allDonors}
              onChange={handleCheckboxChange} />
            <label className='size16700' for="allDonors">
              All Donors
            </label>
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <div class="form-check">
            <input
              className='input-check'
              id="other-donors"
              type="checkbox"
              name="other"
              value="Other"
              checked={selectedCheckboxes.other}
              onChange={handleCheckboxChange} />
            <label className='size16700' for="other-donors">
              Other
            </label>
          </div>
        </div>
      </div>
      {
        otherDonorsChecked ? (
          <div style={{ marginTop: '24px' }}>
            <label for="floatingTextarea2Other" class="form-label size14700" style={{ marginBottom: '4px' }}>Other</label>
            <textarea class="form-control custom-input" id="floatingTextarea2Other" value={donorTypeOtherComment} onChange={changeOtherComment} style={{ height: '100px' }}></textarea>
          </div>
        ) : ''
      }
    </div>
  )
}

export default DonorType;

