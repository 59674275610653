import React, { useEffect } from 'react'
import { useState } from 'react';
import { CHECK_TOKEN_SENDEMIAL, RESET_PASSWORD } from '../api/URLs';
import PrimaryButton from '../container/buttons/PrimaryButton'
import { validationResetPassword } from '../functions/validation';
import '../styles/changePassword.css'
import { validatePassword } from '../utils/validation';
import { Buffer } from 'buffer'
import API from '../utils/request'
import { colors } from '../constants/Colors';
import LoadingOverlay from '../container/loading/LoadingOverlay';
import MessageModalMain from '../container/modal/MessageModalMain';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

const ResetPassword = () => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({});
    const messageModalRef = useRef('');

    const [searchParams, setSearchParams] = useSearchParams();
    const [newPassword, setNewPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [tokenPassword, setTokenPassword] = useState()

    useEffect(() => {
        if (searchParams.get('t')) {
            checkTokenSendEmail(searchParams.get('t'));
        }
        else {
            window.location.href = "/login";
        }
    }, [])

    const checkTokenSendEmail = (token) => {
        var param = {
            "pwdToken": token
        }
        API.post(CHECK_TOKEN_SENDEMIAL, param).then((res) => {
            if (res.data === false) {
                setTokenPassword(token)
            }
            else {
                messageModalRef.current.showWarning("Your access token has expired.", () => {
                    window.location.href = "/login";
                })
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleChangePassword = (e) => {
        e.preventDefault()
        setError(validationResetPassword(newPassword, confirmPassword))
        if (!newPassword || !confirmPassword) {
            return false;
        } else if (!validatePassword(newPassword)) {
            error['validate'] = 'Password is Invalid'
            setError({ ...error })
            return false;
        } else if (newPassword !== confirmPassword) {
            error['notMatch'] = 'Password does not match.'
            setError({ ...error })
            return false;
        }
        setLoading(true)
        var params = {
            "token": tokenPassword,
            "password": Buffer.from(newPassword).toString('base64'),
        }
        API.post(RESET_PASSWORD, params).then((res) => {
            setLoading(false)
            if (res.status === 1) {
                setNewPassword("")
                setConfirmPassword("")
                messageModalRef.current.showSuccess(res.message, () => {
                    localStorage.clear();
                    window.location.href = "/login";
                })
            } else {
                messageModalRef.current.showWarning(res.message)
            }
        }).catch((err) => {
            setLoading(false)
            messageModalRef.current.showWarning(err.message)
        })
    }

    return (
        <div className='row px-lg-0 px-5 gx-0' style={{ marginTop: '40px' }}>
            {loading ? <LoadingOverlay /> : null}
            <MessageModalMain textCentered ref={messageModalRef} />
            <p className='size20700'>Reset Password</p>
            <div className='col-md-8 col-12 margin-password'>
                <div className='row'>
                    <div className='col-md-3 col-12'>
                        <div className='d-flex flex-row align-items-center h-100'>
                            <label className='size16700'>New Password<span className='text-danger'>*</span></label>
                        </div>
                    </div>
                    <div className='col-md-9 col-12'>
                        <input
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            type='password'
                            className={`form-control custom-input  ${error.newPassword && !newPassword ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{error.newPassword ? error.newPassword : ''}</div>
                    </div>
                    <div className='col-md-3 col-12'></div>
                    <div className='col-md-9 col-12'>
                        <p className={`size14500 ${error.validate && !validatePassword(newPassword) ? 'text-danger' : ''}`} style={{ color: colors.grayColor }}>New password should be 8 to 32 characters long, and include numbers, uppercase, and lowercase letters</p>

                    </div>
                </div>
                <div className='row' style={{ marginTop: '40px' }}>
                    <div className='col-md-3 col-12'>
                        <div className='d-flex flex-row align-items-center h-100'>
                            <label className='size16700'>Confirm New Password<span className='text-danger'>*</span></label>
                        </div>
                    </div>
                    <div className='col-md-9 col-12'>
                        <input
                            value={confirmPassword}
                            onChange={(e) => { setConfirmPassword(e.target.value) }}
                            type='password'
                            className={`form-control custom-input  ${(error.confirmPassword && !confirmPassword) || (error.notMatch && newPassword !== confirmPassword) ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{error.confirmPassword && !confirmPassword ? error.confirmPassword : (error.notMatch && newPassword !== confirmPassword) ? error.notMatch : ''}</div>
                    </div>
                </div>
            </div>
            <div className='col-12' style={{ marginTop: '46px' }}>
                <div className='d-flex justify-content-center'>
                    <PrimaryButton
                        title="Submit"
                        color='white'
                        onClick={handleChangePassword}
                    />
                </div>
            </div>
        </div>
    )
}

export default ResetPassword