export const colors = {
  // primaryColor: 'rgba(252,91,91,1)',
  primaryColor: '#FC1921',
  gray3: '#808284',
  tintedBackground: 'rgba(242,245,250,1)',
  textPrimaryColor: '#364354',
  textSecondaryColor: 'rgba(173,177,182,1)',
  iconButtonPrimaryColor: '#727B86',
  iconButtonSecondaryColor: '#EDEFF2',
  borderColor: "#E2DFDA",
  appointmentColor: '#00C7A9',
  nextAvailableAppointmentColor: '#FF815B',
  todayColor: '#0E56A5',
  textButtonColor: '#0E56A5',
  authorColor: '#1C64CA',
  greenText: 'rgba(69,185,148,1)',
  blueText: '#24A2EE',
  redText: '#FB6075',
  canceled: '#FF8800',
  completed: '#007E33',
  missed: '#cc3300',
  progressBarBG: '#ecc3a5',
  progressBarProgress: '#e29976',
  backColor: "#FC1921",

  cslRed: "#FC1522",
  midnight: "#0F0A2F",
  midnightLight: "#454255",
  softWhite: "#FCFCFA",
  offWhite: "#F1EFEA",
  warmGray: "#E2DFDA",
  plasmaGold: "#fda62d",
  maroon: "#931537",
  medicalGreen: "#6bc3e2",
  skyBlue: "#1d7e8b",
  error: "#C32828",
  positive: "#248027",
  inactive: "#B5BDC1",
  placeholdercolor: "#4F5556",
  normalWaitTimes: "#FFA814",
  disableDay: '#00000029',
  newgray: "#0000004D",
  disableInput: '#e9ecef',
  grayColor: '#949494'
};
