import React, { Fragment, useEffect } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { LOGIN_SUCCESS, RESTORE_USER } from '../modules/auth/constants'
import * as Storage from '../utils/storage';
import { storageKeys } from '../constants/storage';
import DesktopHeader from '../container/layouts/desktopHeader';
import MobileHeader from '../container/layouts/mobileHeader';

const Layout = (props) => {
    useEffect(()=> {
        if (localStorage.getItem(storageKeys.TOKEN)) {
            var datas = {}
            var data = JSON.parse(Storage.getString(storageKeys.USER))
            data.user = data;

            datas.user = data
            datas.token = Storage.getString(storageKeys.TOKEN)
            props.dispatch({
                type: LOGIN_SUCCESS,
                payload: datas
            })

            props.dispatch({
                type: RESTORE_USER,
                payload: JSON.parse(Storage.getString(storageKeys.USER))
            })

        }
    }, []);
    const { token, user } = props;
    return (
        <Fragment>
            <DesktopHeader user={user} token={token} />
            <MobileHeader user={user} token={token}/>
            <main>
                <div className='container-lg'>
                    {props.children}
                </div>
            </main>
        </Fragment>
    )
}


const mapDispatchToProps = dispatch => ({ dispatch })

const mapStateToProps = state => ({
    user: state.authReducer.user,
    token: state.authReducer.token
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Layout));