export const dataSubmission = [
  {
    name: "Before",
  },
  {
    name: "After",
  },
  {
    name: "Between",
  },
];

export const dataSearch = [
  {
    name: "Campaign Title",
  },
];

export const progressStatus = [
  {
    name: "Pending",
    value: "pending",
    isCheck: false,
  },
  {
    name: "In Review",
    value: "in-review",
    isCheck: false,
  },
  {
    name: "Approved",
    value: "approved"
  },
  {
    name: "In Progress",
    value: "in-progress",
    isCheck: false,
  },
  {
    name: "Campaign Live",
    value: "campaign-live",
    isCheck: false,
  },
  {
    name: "Completed",
    value: "completed",
    isCheck: false,
  },
  {
    name: "Archive",
    value: "archive",
    isCheck: false,
  },
  {
    name: "Request Denied",
    value: "request-denied",
    isCheck: false,
  }
];