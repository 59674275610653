import React, { useEffect, useState } from 'react'
import { citiesFromState, parseCitiesFromPlasmaCenters, parsePlasmaCentersByStateAndCity, parseStatesFromPlasmaCenters, PRCity } from '../utils/centers';
import API from '../utils/request'
import { LOCATION_URL } from "../api/URLs";
import { filterPlasmaCentersByStateAndCity } from '../utils/StatesList';
// import { Axios } from 'axios';
import Axios from "axios";
import { API_LOCATION } from '../config/api'
import { connect } from 'react-redux';
import DropupIcon from '../assets/images/icons/dropup-icon.svg'
import DropdownIcon from '../assets/images/icons/dropdown-icon.svg'
import { colors } from '../constants/Colors';
import BlockLoading from '../container/blockLoading/blockLoading';

const LocationCenter = (props) => {

    const {
        checkAllLocation,
        setCheckAllLocation,
        setSelectedState,
        selectedState,
        setSelectedCities,
        selectedCities,
        token,
        setSelectedCenter,
        selectedCenter,
        setLocationCheckOther,
        locationCheckOther,
        setLocationOtherComment,
        locationOtherComment,
        locationAll, 
        setLocationAll,
        locationAllPR,
        setLocationAllPR
    } = props

    const [locationCenter, setLocationCenter] = useState([])

    /* Value of Data */
    const [fetchCity, setFetchCity] = useState([])
    const [fetchCenter, setFetchCenter] = useState([])

    //Active
    const [stateActive, setStateActive] = useState()
    const [cityActive, setCityActive] = useState()
    const [centerActive, setCenterActive] = useState()
    const [otherCommentActive, setOtherCommentActive] = useState(false)

    //Label
    const [stateLabel, setStateLabel] = useState([])
    const [centerLabel, setCenterLabel] = useState([])

    const [checkAllState, setCheckAllState] = useState(false)
    const [checkCity, setCheckCity] = useState(false)
    const [checkAllCenter, setCheckAllCenter] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchDonorCarePlasmaCenter()
    }, [])

    const fetchDonorCarePlasmaCenter = () => {
        setLoading(true)
        var dataForm = {
            serviceName: 'location',
            serviceType: 'getSavableLocations'
        }
        Axios.post(API_LOCATION + LOCATION_URL, dataForm,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                }
            })
            .then((res) => {
                setLoading(false)
                setLocationCenter(res.data.locations.concat(PRCity))
                if(locationCheckOther === 'yes'){
                    setOtherCommentActive(true)
                } else {

                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err);
            })
    }

    const stateLocation = parseStatesFromPlasmaCenters(locationCenter);

    useEffect(() => {
        if(locationCheckOther === 'yes'){
            setOtherCommentActive(true)
        } 
    }, [locationCheckOther])

    useEffect(() => {
        if(checkAllLocation === 'no') {
            if(selectedState.length > 0) {
                let stateName = []
                stateLocation.forEach(it => {
                    if(selectedState.includes(it.key)) {
                        stateName.push(it.name)
                    }
                });
                if (stateName.length > 0) {
                    setStateLabel(stateName)
                    const cities = parseCitiesFromPlasmaCenters(locationCenter, (selectedState ? selectedState : ''));
                    if (cities.length === 0) {
                        setSelectedCities([])
        
                        setCenterLabel([])
                        setSelectedCenter([])
                        const plasmaCenter = parsePlasmaCentersByStateAndCity(locationCenter, (selectedState ? selectedState : ""), [])
                        setFetchCenter(plasmaCenter)
        
                    }
                    else {
                        let cityArr = [];
        
                        cities.forEach(ct => {
                            if (selectedCities.includes(ct)) {
                                cityArr.push(ct)
                            }
                        });
        
                        const plasmaCenter = parsePlasmaCentersByStateAndCity(locationCenter, (selectedState ? selectedState : ""), cities)
                        let centerArray = [];
                        plasmaCenter.forEach(center => {
                            if (selectedCenter.includes(`${center.address.city} ${center.locationID}`)) {
                                centerArray.push(`${center.address.city} ${center.locationID}`)
                            }
                        });
                        setCenterLabel(centerArray)
                        setFetchCenter(plasmaCenter)
        
                        setSelectedCities(cityArr);
                    }
                    setFetchCity(cities)
                }
            }
        }
    }, [checkAllLocation, locationCenter])

    const handleClearCenter = (item) => {
        setStateLabel([])
        setSelectedCities([])
        setCenterLabel([])

        setSelectedState([])
        const cities = parseCitiesFromPlasmaCenters(locationCenter, (selectedState ? '' : ''));
        setFetchCity(cities)

        setSelectedCities([])
        const plasmaCenter = parsePlasmaCentersByStateAndCity(locationCenter, (selectedState ? '' : ""), [])
        setFetchCenter(plasmaCenter)
        setSelectedCenter([])
        setCenterLabel([])
        setCheckCity(false)
        setCheckAllCenter(false)
        setCheckAllState(false)
    }

    const handleState = (item) => {
        if (stateLabel.includes(item.name)) {
            stateLabel.splice(stateLabel.indexOf(item.name), 1);
            setStateLabel([...stateLabel])
            selectedState.splice(selectedState.indexOf(item.key), 1);
            setSelectedState([...selectedState])
            const cities = parseCitiesFromPlasmaCenters(locationCenter, (selectedState ? selectedState : ''));

            if (cities.length === 0) {
                setSelectedCities([])

                setCenterLabel([])
                setSelectedCenter([])
                const plasmaCenter = parsePlasmaCentersByStateAndCity(locationCenter, (selectedState ? selectedState : ""), [])
                setFetchCenter(plasmaCenter)

            } else {
                let cityArr = [];

                cities.forEach(ct => {
                    if (selectedCities.includes(ct)) {
                        cityArr.push(ct)
                    }
                });

                const plasmaCenter = parsePlasmaCentersByStateAndCity(locationCenter, (selectedState ? selectedState : ""), cities)
                let centerArray = [];
                plasmaCenter.forEach(center => {
                    if (selectedCenter.includes(`${center.address.city} ${center.locationID}`)) {
                        centerArray.push(`${center.address.city} ${center.locationID}`)
                    }
                });
                setCenterLabel(centerArray)
                setFetchCenter(plasmaCenter)

                setSelectedCities(cityArr);
            }
            setFetchCity(cities)

        } else {
            stateLabel.push(item.name)
            setStateLabel([...stateLabel])
            selectedState.push(item.key)
            setSelectedState([...selectedState])
            const cities = parseCitiesFromPlasmaCenters(locationCenter, (selectedState ? selectedState : ''));
            setFetchCity(cities)
        }
        // uncheck
        if (stateLabel.length === stateLocation.length) {
            setCheckAllState(true)
        } else {
            setCheckAllState(false)
        }
        if (selectedState.length <= 0) {
            setCheckCity(false)
            setCheckAllCenter(false)
        }
    }

    const handleCheckAllState = (checked) => {
        if (checked) {
            setCheckAllState(checked)
            let stateName = stateLocation.map(it => it.name)
            let stateKey = stateLocation.map(it => it.key)
            setStateLabel(stateName)
            setSelectedState(stateKey)
            const cities = parseCitiesFromPlasmaCenters(locationCenter, (stateKey ? stateKey : ''));
            setFetchCity(cities)
        } else {
            setCheckAllState(checked)
            setStateLabel([])
            setSelectedState([])
            setFetchCity([])
            setSelectedCities([])
            setSelectedCenter([])
            setCenterLabel([])
            const plasmaCenter = parsePlasmaCentersByStateAndCity(locationCenter, (selectedState ? selectedState : ""), [])
            setFetchCenter(plasmaCenter)
            setCheckCity(false)
            setCheckAllCenter(false)
        }

    }

    const handleCity = (value) => {
        if (selectedCities.includes(value)) {
            selectedCities.splice(selectedCities.indexOf(value), 1);
            setSelectedCities([...selectedCities])
            const plasmaCenter = parsePlasmaCentersByStateAndCity(locationCenter, (selectedState ? selectedState : ""), selectedCities)
            let centerArray = [];
            plasmaCenter.forEach(center => {
                if (selectedCenter.includes(`${center.address.city} ${center.locationID}`)) {
                    centerArray.push(`${center.address.city} ${center.locationID}`)
                }
            });
            setCenterLabel(centerArray)
            setFetchCenter(plasmaCenter)
        } else {
            selectedCities.push(value)
            setSelectedCities(selectedCities)
            const plasmaCenter = parsePlasmaCentersByStateAndCity(locationCenter, (selectedState ? selectedState : ""), selectedCities)
            setFetchCenter(plasmaCenter)
        }
        // uncheck
        if (selectedCities.length === fetchCity.length) {
            setCheckCity(true)
        } else {
            setCheckCity(false)
        }
    }


    const handleSelectAllCity = (checked) => {
        if (checked) {
            setCheckCity(checked)
            setSelectedCities(fetchCity)
            const plasmaCenter = parsePlasmaCentersByStateAndCity(locationCenter, (selectedState ? selectedState : ""), fetchCity)
            setFetchCenter(plasmaCenter)
        } else {
            setCheckCity(checked)
            setSelectedCities([])
            setCenterLabel([])
            setCheckAllCenter(false)
            const plasmaCenter = parsePlasmaCentersByStateAndCity(locationCenter, (selectedState ? selectedState : ""), [])
            setFetchCenter(plasmaCenter)
            setCheckAllCenter(false)
        }
    }

    const handlePlasmaCenter = (value) => {
        const dataCenter = `${value.address.city} ${value.locationID}`;
        if (centerLabel.includes(dataCenter)) {
            centerLabel.splice(centerLabel.indexOf(dataCenter), 1);
            setCenterLabel([...centerLabel])

            selectedCenter.splice(selectedCenter.indexOf(dataCenter), 1);
            setSelectedCenter(centerLabel)
        } else {
            centerLabel.push(dataCenter)
            setCenterLabel([...centerLabel])

            selectedCenter.push(value)
            setSelectedCenter(centerLabel)
        }
        // uncheck
        if (selectedCenter.length === fetchCenter.length) {
            setCheckAllCenter(true)
        } else {
            setCheckAllCenter(false)
        }
    }

    const handleSelectAllCenter = (checked) => {
        if (checked) {
            setCheckAllCenter(checked)
            setCenterLabel(fetchCenter.map(item => `${item.address.city} ${item.locationID}`))
            setSelectedCenter(fetchCenter.map(item => `${item.address.city} ${item.locationID}`))
        } else {
            setCheckAllCenter(checked)
            setSelectedCenter([])
            setCenterLabel([])
        }
    }

    const handleCheckLocation = (e, locationValue) => {
        const { checked } = e.target;
        if (checked) {
            setCheckAllLocation("yes");
            setLocationAll(locationValue);
        } else {
            if(locationAllPR){
                setCheckAllLocation("yes");
                setLocationAll("")
            }else{
                setCheckAllLocation("no");
                setLocationAll("");
            }
            
        }
    };

    const handleCheckLocationPR = (e, locationValue) => {
        console.log("locationValue", locationValue);
        
        const { checked } = e.target;
        if (checked) {
            setCheckAllLocation("yes");
            setLocationAllPR(locationValue)
        } else {
            if(locationAll){
                setCheckAllLocation("yes");
                setLocationAllPR("")
            }else{
                setCheckAllLocation("no");
                setLocationAllPR("")
            }
        }
    };

    const handleOtherCommentActive = (e) => {
        const { checked } = e.target;
        setOtherCommentActive(checked)
        if (checked) {
            setLocationCheckOther("yes")
        } else {
            setLocationCheckOther("no")
        }
    }

    return (
        <div>
            <p className='size14700' style={{ marginBottom: '24px' }}>Location</p>
            <div>
                <div className='d-flex flex-row'>
                    <div className="form-check" style={{ marginRight: '80px' }}>
                        <input
                            className='input-check'
                            type="checkbox"
                            id="lapseDonorsUS"
                            name="lapseDonorsUS"
                            value="Lapse Donors"
                            checked={locationAll === 'all_us_centers'}
                            onChange={(e) => handleCheckLocation(e, 'all_us_centers')}
                        />
                        <label className='size16700' htmlFor="lapseDonorsUS">
                            All US Centers
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className='input-check'
                            type="checkbox"
                            id="lapseDonorsPR"
                            name="lapseDonorsPR"
                            value="Lapse DonorsPR"
                            checked={locationAllPR === 'all_pr_centers'}
                            onChange={(e) => handleCheckLocationPR(e, 'all_pr_centers')}
                        />
                        <label className='size16700' htmlFor="lapseDonorsPR">
                            All PR Centers
                        </label>
                    </div>
                </div>

                {
                    checkAllLocation === 'no' && (
                        <div style={{ marginTop: '24px' }}>
                            <div>
                                <div style={{ position: 'relative' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <label for="StateDrop" class="form-label size14700" style={{ marginBottom: '4px' }}>State</label>
                                        <label for="" class="form-label size14700" onClick={() => handleClearCenter()} style={{ marginBottom: '4px', color: 'red', cursor: 'pointer' }}>Clear</label>
                                    </div>
                                    <button
                                        style={{
                                            height: '50px',
                                            width: '100%',
                                            backgroundColor: 'white',
                                            border: '1px solid #ced4da',
                                            borderRadius: '5px',
                                            padding: '9px',
                                            position: 'relative',
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setCityActive(!cityActive)
                                        }}
                                        id="StateDrop"
                                        className='d-flex flex-row align-items-center'
                                    >
                                        {/* <p className="size16600 mb-0">{stateLabel ? stateLabel.slice(0, 7).map((value) => { return `${value}, ` }) : ''} {`${stateLabel.length > 7 ? '...' : ''}`}</p> */}
                                        <p className="size16600 mb-0">
                                            {stateLabel ? stateLabel.slice(0, 7).map((value, index) => (
                                                <span key={index}>
                                                    {value}
                                                    {index < stateLabel.slice(0, 7).length - 1 ? ', ' : ''}
                                                </span>
                                            )) : ''}
                                            {stateLabel.length > 7 ? '...' : ''}
                                        </p>

                                        <img style={{ position: 'absolute', right: '9px', top: '18px' }} alt="" src={cityActive ? DropupIcon : DropdownIcon} width='18px' />
                                    </button>
                                    <div className={`dropdown-menu location audience-dropdown cusScrollDrop ${cityActive ? 'show' : ''}`} style={{ zIndex: 10 }}
                                        onMouseLeave={(e) => {
                                            setCityActive(false);
                                        }}>
                                        {
                                            stateLocation && stateLocation.length > 0 && (
                                                <>
                                                    <a href class="dropdown-item size16400" onClick={() => handleCheckAllState(!checkAllState)} style={{ padding: '9px', cursor: 'pointer' }}>
                                                        <input
                                                            className='input-check ms-2'
                                                            type="checkbox"
                                                            checked={checkAllState}
                                                        />
                                                        All
                                                    </a>
                                                    <div className="dropdown-divider"></div>
                                                </>
                                            )
                                        }
                                        {
                                            loading ? <BlockLoading /> :
                                                <>
                                                    {stateLocation.map((item, index) => (
                                                        <>
                                                            <a href class="dropdown-item size16400" onClick={() => handleState(item)} style={{ padding: '9px', cursor: 'pointer' }}>
                                                                <input
                                                                    className='input-check ms-2'
                                                                    type="checkbox"
                                                                    id={item.name}
                                                                    name={item.name}
                                                                    value={item}
                                                                    checked={stateLabel.includes(item.name)}
                                                                />
                                                                {item.name}</a>
                                                            {index + 1 !== stateLocation.length ? <div className="dropdown-divider"></div> : ''}
                                                        </>
                                                    ))}
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: '24px' }}>
                                <div>
                                    <div style={{ position: 'relative' }}>
                                        <label for="btnCity" class="form-label size14700" style={{ marginBottom: '4px' }}>City</label>
                                        <button
                                            style={{
                                                height: '50px',
                                                width: '100%',
                                                backgroundColor: 'white',
                                                border: '1px solid #ced4da',
                                                borderRadius: '5px',
                                                padding: '9px',
                                                position: 'relative',
                                                backgroundColor: `${fetchCity.length > 0 ? "transparent" : colors.disableInput}`
                                            }}
                                            id="btnCity"
                                            disabled={fetchCity.length > 0 ? false : true}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setStateActive(!stateActive)
                                            }}
                                            className='d-flex flex-row align-items-center'
                                        >
                                            {/* <p className="size16600 mb-0">{selectedCities ? selectedCities.slice(0, 7).map((value) => `${value}, `) : ''} {selectedCities.length > 7 ? '...' : ''} </p> */}
                                            <p className="size16600 mb-0">
                                                {selectedCities ? selectedCities.slice(0, 7).map((value, index) => (
                                                    <span key={index}>
                                                        {value}{index < selectedCities.slice(0, 7).length - 1 ? ', ' : ''}
                                                    </span>
                                                )) : ''}
                                                {selectedCities.length > 7 ? '...' : ''}
                                            </p>

                                            <img style={{ position: 'absolute', right: '9px', top: '18px' }} alt="" src={stateActive ? DropupIcon : DropdownIcon} width='18px' />
                                        </button>
                                        <div className={`dropdown-menu location audience-dropdown cusScrollDrop ${stateActive ? 'show' : ''}`} style={{ zIndex: 10 }}
                                            onMouseLeave={(e) => {
                                                setStateActive(false);
                                            }}>
                                            {
                                                fetchCity && fetchCity.length > 0 && (
                                                    <>
                                                        <a href class="dropdown-item size16400" onClick={() => handleSelectAllCity(!checkCity)} style={{ padding: '9px', cursor: 'pointer' }}>
                                                            <input
                                                                className='input-check ms-2'
                                                                type="checkbox"
                                                                checked={checkCity}
                                                            />
                                                            All
                                                        </a>
                                                        <div className="dropdown-divider"></div>
                                                    </>
                                                )
                                            }
                                            {fetchCity.map((item, index) => (
                                                <>
                                                    <a href class="dropdown-item size16400" onClick={() => handleCity(item)} style={{ padding: '9px', cursor: 'pointer' }}>
                                                        <input
                                                            className='input-check ms-2'
                                                            type="checkbox"
                                                            id={item}
                                                            name={item}
                                                            value={item}
                                                            checked={selectedCities.includes(item)}
                                                        />
                                                        {item}</a>
                                                    {index + 1 !== fetchCity.length ? <div className="dropdown-divider"></div> : ''}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: '24px' }}>
                                <div>
                                    <div style={{ position: 'relative' }}>
                                        <label for="btnCenter" class="form-label size14700" style={{ marginBottom: '4px' }}>Center</label>
                                        <button
                                            style={{
                                                height: '50px',
                                                width: '100%',
                                                backgroundColor: 'white',
                                                border: '1px solid #ced4da',
                                                borderRadius: '5px',
                                                padding: '9px',
                                                position: 'relative',
                                                backgroundColor: `${fetchCenter.length > 0 ? "transparent" : colors.disableInput}`
                                            }}
                                            disabled={fetchCenter.length > 0 ? false : true}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCenterActive(!centerActive)
                                            }}
                                            id="btnCenter"
                                            className='d-flex flex-row align-items-center'
                                        >
                                            {/* <p className="size16600 mb-0">{centerLabel ? centerLabel.slice(0, 5).map((value) => { return `${typeof value === 'string' ? value : value.name}, ` }) : ''} {centerLabel.length > 7 ? '...' : ''}</p> */}
                                            <p className="size16600 mb-0">
                                                {centerLabel ? centerLabel.slice(0, 5).map((value, index) => (
                                                    <span key={index}>
                                                        {typeof value === 'string' ? value : value.name}
                                                        {index < centerLabel.slice(0, 5).length - 1 ? ', ' : ''}
                                                    </span>
                                                )) : ''}
                                                {centerLabel.length > 7 ? '...' : ''}
                                            </p>

                                            <img style={{ position: 'absolute', right: '9px', top: '18px' }} alt="" src={centerActive ? DropupIcon : DropdownIcon} width='18px' />
                                        </button>
                                        <div className={`dropdown-menu location audience-dropdown cusScrollDrop ${centerActive ? 'show' : ''}`} style={{ zIndex: 10 }}
                                            onMouseLeave={(e) => {
                                                setCenterActive(false);
                                            }}>
                                            {
                                                fetchCenter && fetchCenter.length > 0 && (
                                                    <>
                                                        <a href class="dropdown-item size16400" onClick={() => handleSelectAllCenter(!checkAllCenter)} style={{ padding: '9px', cursor: 'pointer' }}>
                                                            <input
                                                                className='input-check ms-2'
                                                                type="checkbox"
                                                                checked={checkAllCenter}
                                                            />
                                                            All
                                                        </a>
                                                        <div className="dropdown-divider"></div>
                                                    </>
                                                )
                                            }
                                            {fetchCenter.map((item, index) => (
                                                <>
                                                    <a href class="dropdown-item size16400" onClick={() => handlePlasmaCenter(item)} style={{ padding: '9px', cursor: 'pointer' }}>
                                                        {/* `${value.address.city} ${value.locationID}` */}
                                                        <input
                                                            className='input-check ms-2'
                                                            type="checkbox"
                                                            id={`${item.address.city} ${item.locationID}`}
                                                            name={`${item.address.city} ${item.locationID}`}
                                                            value={`${item.address.city} ${item.locationID}`}
                                                            checked={centerLabel.includes(`${item.address.city} ${item.locationID}`)}
                                                        />
                                                        {`${item.address.city} ${item.locationID}`}</a>
                                                    {index + 1 !== fetchCenter.length ? <div className="dropdown-divider"></div> : ''}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '100%', height: `${centerLabel.length >= 25 ? '180px' : ''}`, overflowY: `${centerLabel.length >= 25 ? 'scroll' : 'unset'}`, marginTop: '24px', marginBottom: '24px' }}>
                                <p className='size16500 mb-0 d-flex flex-wrap'>Current selected centers: &nbsp; {centerLabel ? centerLabel.map((value, index) => (
                                    <p className='size16400'>{`${typeof value === 'string' ? value : value.name}${index + 1 !== centerLabel.length ? ',' : ''}`}&nbsp;</p>
                                )) : ''}</p>
                            </div>
                        </div>
                    )
                }

                <div class="form-check" style={{ marginTop: '24px' }}>
                    <input
                        className='input-check'
                        type="checkbox"
                        id="lapseDonors"
                        name="lapseDonors"
                        value="Lapse Donors"
                        checked={locationCheckOther === 'yes'}
                        onChange={handleOtherCommentActive}
                    />
                    <label className='size16700'>
                        Other
                    </label>
                    {
                        otherCommentActive ? (
                            <div style={{ marginTop: '24px' }}>
                                <label for="floatingTextarea2" class="form-label size14700" style={{ marginBottom: '4px' }}>Other</label>
                                <textarea class="form-control custom-input" id="floatingTextarea2" value={locationOtherComment} onChange={(e) => setLocationOtherComment(e.target.value)} style={{ height: '100px' }}></textarea>
                            </div>
                        ) : ''
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateTopProps = (state) => ({
    token: state.authReducer.token
})

export default connect(mapStateTopProps)(LocationCenter);