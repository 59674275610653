// // Staging ENV
export const ADMIN_ENDPOINT = "https://csl-backend.staging.nowbst.com/";
export const API_ENDPOINT = "https://csl-backend.staging.nowbst.com/reward/";
export const API_ENDPOINT_REGISTER = "https://csl-backend.staging.nowbst.com/authSteps/";
export const ADMIN_ENDPOINT_DONOR = "https://csl-backend.staging.nowbst.com/intake-form/";
export const API_LOCATION = "https://donorapp-apiqa.cslplasma.com";

// QA ENV
// export const ADMIN_ENDPOINT = "https://donorapp-apiqa.cslplasma.com/";
// export const API_ENDPOINT = "https://donorapp-apiqa.cslplasma.com/reward/";
// export const API_ENDPOINT_REGISTER = "https://donorapp-apiqa.cslplasma.com/authSteps/";
// export const ADMIN_ENDPOINT_DONOR = "https://donorapp-apiqa.cslplasma.com/intake-form/";
// export const API_LOCATION = "https://donorapp-apiqa.cslplasma.com";

// // PROD ENV
// export const ADMIN_ENDPOINT = "https://donorapp-api.cslplasma.com/";
// export const API_ENDPOINT = "https://donorapp-api.cslplasma.com/reward/";
// export const API_ENDPOINT_REGISTER = "https://donorapp-api.cslplasma.com/authSteps/";
// export const ADMIN_ENDPOINT_DONOR = "https://donorapp-api.cslplasma.com/intake-form/";
// export const API_LOCATION = "https://donorapp-api.cslplasma.com";

export default {
    ADMIN_ENDPOINT,
    API_ENDPOINT,
    API_ENDPOINT_REGISTER
};