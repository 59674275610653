import './App.css';
import React from 'react';
import { Provider } from 'react-redux';

import {rootSaga, store, sagaMiddleware} from './modules/redux/sagas';
import AppRouter from './AppRouter';
sagaMiddleware.run(rootSaga);


function App() {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
}

export default App;
