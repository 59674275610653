import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import CalendarIcon from '../../assets/images/icons/calendar-icon.svg'
import { CalendarCSL } from '../../container/calendar/CalendarCSL'
import moment from 'moment';

const CampaignDetails = (props) => {

    const {
        setCampaignTitle,
        campaignTitle,
        setDateFrom,
        dateFrom,
        setDateTo,
        dateTo,
        campaignGoal,
        setCampaginGoal,
        validateFields,
        dateRangeError
    } = props

    useEffect(() => {
        document.addEventListener("click", handleClickOutSide, true)
        return () => {
            document.removeEventListener('click', handleClickOutSide);
          };
    }, [])

    const refOne = useRef(null)

    // const handleClickOutSide = (e) => {
    //     if (!refOne.current.contains(e.target)) {
    //         setFromActive(false)
    //         setToActive(false)
    //     }
    // }
    const handleClickOutSide = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setFromActive(false);
            setToActive(false);
        }
    };

    const [toActive, setToActive] = useState(false)
    const [fromActive, setFromActive] = useState(false)

    const changeDateTo = (date) => {
        setDateTo(date)
    }
    const changeDateFrom = (date) => {
        setDateFrom(date)
    }

    return (
        <div>
            <h2 className='size24700' style={{ marginBottom: '40px' }}>Campaign Details</h2>
            <div>
                <div style={{ marginBottom: '40px' }}>
                    <label for="Campaign-Title" class="form-label size18700" style={{ marginBottom: '4px' }}>Campaign Title*</label>
                    <input type="text" class={`form-control custom-input ${validateFields.campaignTitle && !campaignTitle ? 'is-invalid' : ''}`} id="Campaign-Title" value={campaignTitle} onChange={(e) => setCampaignTitle(e.target.value)} aria-describedby="emailHelp" />
                    <div className="invalid-feedback">{validateFields.campaignTitle && validateFields.campaignTitle}</div>
                </div>
                <div style={{ marginBottom: '40px' }}>
                    <label for="Campaign-Goal" class="form-label size18700" style={{ marginBottom: '4px' }}>Campaign Goal*</label>
                    <textarea onChange={(e) => setCampaginGoal(e.target.value)} value={campaignGoal} class={`form-control custom-input ${validateFields.campaignGoal && !campaignGoal ? 'is-invalid' : ''}`} id="Campaign-Goal" style={{ height: '100px' }}></textarea>
                    <div className="invalid-feedback">{validateFields.campaignGoal && validateFields.campaignGoal}</div>
                </div>
                <div>
                    <label for="exampleInputEmail1" class="form-label size18700" style={{ marginBottom: '4px' }}>Campaign Duration*</label>
                    <div className='d-flex flex-row align-items-center justify-content-between'>
                        <div className='calendar-box' ref={refOne}>
                            <p>{dateFrom ? (moment(dateFrom).format('MM / DD / YYYY')) : 'Select date'}</p>
                            <img onClick={() => { setFromActive(!fromActive); setToActive(false); }} style={{ position: 'absolute', right: '9px', top: '12px' }} alt="" src={CalendarIcon} />
                        </div>
                        <div style={{ width: '10px', height: '1px', backgroundColor: 'black', marginLeft: '16px', marginRight: '16px' }}></div>
                        <div className='calendar-box' ref={refOne}>
                            <p>{dateTo ? (moment(dateTo).format('MM / DD / YYYY')) : 'Select date'}</p>
                            <img onClick={() => { setToActive(!toActive); setFromActive(false); }} style={{ position: 'absolute', right: '9px', top: '12px' }} alt="" src={CalendarIcon} />
                        </div>
                    </div>
                    {
                        dateRangeError && (!dateFrom || !dateTo) ? (
                            <p className='text-danger size12400 mb-0 mt-2'>{dateRangeError}</p>
                        ) : ''
                    }
                    <div ref={refOne}>
                    <CalendarCSL float={'left'} activeCalendar={fromActive} setactiveCalendar={setFromActive} changeDate={changeDateFrom} date={dateFrom} maxDate={true} handleActiveCalendar={() => { setFromActive(!fromActive); setToActive(false); }} />
                    <CalendarCSL float={'right'} activeCalendar={toActive} setactiveCalendar={setToActive} changeDate={changeDateTo} date={dateTo} maxDate={true} handleActiveCalendar={() => { setToActive(!toActive); setFromActive(false); }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignDetails