import i18next from "i18next";
import * as Actions from "./constants";

const initState = {
    user: null,
    token: "",
    isFromMobile: false,
    lang: "en",
    registerInfo: {
      token: "",
      firstName: "",
      lastName: "",
      dob: "",
      email: "",
      phone: null,
      isSentCaptcha: false,
      code: "",
    },

}

export default function authReducer(state = initState, action = {}) {
  switch (action.type) {
    case Actions.LOGIN_SUCCESS:
      return {
        user: action.payload.user,
        token: action.payload.token
      };
    case Actions.LOGIN_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case Actions.RESTORE_USER:
      return {
        ...state,
        user: action.payload
      };
    case Actions.UPDATE_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          donorThumbnailPath: action.payload
        }
      };
    case Actions.SET_REGISTER_INFO:
      return {
        ...state,
        registerInfo: action.payload
      };
    case Actions.CHANGE_LANGAUGE:
      return {
        ...state,
        lang: action.payload
      };
    default:
      return state;
  }
}
  