import React from 'react'
import './buttonStyles.css'

const PrimaryButton = (props) => {
    const { onClick, title, color, disabled, width, fontSize, fontWeight, className } = props
    return (
        <button onClick={onClick} className={`${className} primary_button`} disabled={disabled ? true : false} style={{ opacity: disabled ? 0.2 : '', width: width && width }} >
            <p className='size1880' style={{ marginBottom: '0', color: `${color}`, fontSize: `${fontSize}`, fontWeight: `${fontWeight}` }}>
                {title}
            </p>
        </button>
    )
}

export default PrimaryButton